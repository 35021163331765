import { Observable } from 'rxjs';

export abstract class DataService {

  constructor() { }

  public abstract isDataReady(): Observable<boolean>;

  public abstract obtenerProductos(body: any): Observable<any>;

  public abstract obtenerProducto(id: any): Observable<any>;

  public abstract obtenerPortada(id: any): Observable<any>;

  public abstract obtenerCategorias(): Observable<any>;

  public abstract obtenerClientes(body: any): Observable<any>;

  public abstract crearCliente(values: any): Observable<any>;

  public abstract editarCliente(id: any, body: any): Observable<any>;

  public abstract obtenerCliente(id: any): Observable<any>;

  public abstract crearPedido(values: any): Observable<any>;

  public abstract obtenerPedido(id: any): Observable<any>;

  public abstract obtenerPedidos(body: any, id_cliente?: any, id_vendedor?: any): Observable<any>;

  public abstract obtenerPaises(body: any): Observable<any>;

  public abstract obtenerProvincias(body: any): Observable<any>;

  public abstract obtenerCiudades(body: any): Observable<any>;

  public abstract obtenerLocalizacion(id_ciudad: any): Observable<any>;

  public abstract obtenerDireccion(id: any): Observable<any>;

  public abstract obtenerVendedores(): Observable<any>;

  public abstract obtenerFormasDePago(): Observable<any>;

  public abstract obtenerMonedas(): Observable<any>;

  public abstract obtenerEstados(): Observable<any>;

  public abstract obtenerClientesVendedor(body: any): Observable<any>;

}
