import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  private counter = 0;
  private instancias: any = {};

  constructor() { }

  getIdTable(): string {
    return 'tabla-' + this.counter++;
  }

  getId(): string {
    return 'item-' + this.counter++;
  }

  completeValues(columns: string[], data: any): void {
      columns.map((col) => {
        if (typeof data[col] ===  'undefined') {
          data[col] = '';
        }
      });
  }

  setInstancia(id: any, item: any): void {
    return this.instancias[id] = item;
  }

  getInstancia(id: any): any {
    return this.instancias[id];
  }

}
