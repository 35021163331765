import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepperService {

  steps: any;
  id = 0;
  private subject: any;

  constructor() {
    this.subject = new BehaviorSubject<any>(false);
  }

  obtenerId(): string {
    return 'step-' + this.id++;
  }

  getSubjectStepClick(): Observable<any> {
    return this.subject.asObservable();
  }

  setSubjectStepClick(id: any): void {
    if (!this.subject) {
      this.subject = new BehaviorSubject<any>(id);
    } else {
      return this.subject.next(id);
    }
  }

}
