import { DbTransaction, SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../autorization/auth.service';
import { DataService } from './data.service';

export class DbService extends DataService {
  private storage: SQLiteObject | undefined;
  private isReady: BehaviorSubject<any> = new BehaviorSubject(false);
  private clientes: BehaviorSubject<any> = new BehaviorSubject({
    records: [],
    recordsTotal: 0,
    recordsFiltered: 0
  });
  private categorias: BehaviorSubject<any> = new BehaviorSubject({
    records: [],
    recordsTotal: 0,
    recordsFiltered: 0
  });
  private productos: BehaviorSubject<any> = new BehaviorSubject({
    records: [],
    recordsTotal: 0,
    recordsFiltered: 0
  });
  private pedidos: BehaviorSubject<any> = new BehaviorSubject({
    records: [],
    recordsTotal: 0,
    recordsFiltered: 0
  });
  private paises: BehaviorSubject<any> = new BehaviorSubject({
    records: [],
    recordsTotal: 0,
    recordsFiltered: 0
  });
  private provincias: BehaviorSubject<any> = new BehaviorSubject({
    records: [],
    recordsTotal: 0,
    recordsFiltered: 0
  });
  private ciudades: BehaviorSubject<any> = new BehaviorSubject({
    records: [],
    recordsTotal: 0,
    recordsFiltered: 0
  });
  private selectVendedores: BehaviorSubject<any> = new BehaviorSubject({
    records: [],
    recordsTotal: 0,
    recordsFiltered: 0
  });
  private selectFormasDePagos: BehaviorSubject<any> = new BehaviorSubject({
    records: [],
    recordsTotal: 0,
    recordsFiltered: 0
  });
  private selectMonedas: BehaviorSubject<any> = new BehaviorSubject({
    records: [],
    recordsTotal: 0,
    recordsFiltered: 0
  });
  private selectEstados: BehaviorSubject<any> = new BehaviorSubject({
    records: [],
    recordsTotal: 0,
    recordsFiltered: 0
  });
  private selectClientes: BehaviorSubject<any> = new BehaviorSubject({
    records: [],
    recordsTotal: 0,
    recordsFiltered: 0
  });
  private localizacion: BehaviorSubject<any> = new BehaviorSubject(undefined);
  private imagen: BehaviorSubject<any> = new BehaviorSubject(undefined);
  private producto: BehaviorSubject<any> = new BehaviorSubject(undefined);
  private cliente: BehaviorSubject<any> = new BehaviorSubject(undefined);
  private pedido: BehaviorSubject<any> = new BehaviorSubject(undefined);
  private direccion: BehaviorSubject<any> = new BehaviorSubject(undefined);
  private respCrearCliente: BehaviorSubject<any> = new BehaviorSubject(undefined);
  private respEditarCliente: BehaviorSubject<any> = new BehaviorSubject(undefined);
  private respCrearPedido: BehaviorSubject<any> = new BehaviorSubject(undefined);
  private isMobile = false;

  constructor(private platform: Platform, private sqlite: SQLite, private authService: AuthService) {
    super();
    this.platform.ready().then(() => {
      this.setCurrentPlatform();
      if (this.isMobile) {
        this.sqlite.create({
          name: 'mayoristas.db',
          location: 'default'
        })
          .then((db: SQLiteObject) => {
            this.storage = db;
            this.isReady.next(true);
          });
      }
    });
  }

  private setCurrentPlatform(): void {
    if ((window as any).cordova) {
      this.isMobile = true;
    } else {
      this.isMobile = this.platform.is('ios')
        || this.platform.is('android')
        && !(this.platform.is('desktop') || this.platform.is('mobileweb'));
    }
  }

  public isDataReady(): Observable<boolean> {
    return this.isReady.asObservable();
  }

  public obtenerProductos(body: any): Observable<any> {
    const resultado = {
      records: [],
      recordsTotal: 0,
      recordsFiltered: 0
    };
    const hayFiltro = body.filtro && body.filtro.valor && body.filtro.valor.length > 0;
    this.storage?.transaction((tx: DbTransaction) => {
      tx.executeSql('SELECT COUNT(*) AS TOTAL FROM productos', [], (_tx: any, res: any) => {
        resultado.recordsTotal = res.rows.item(0).TOTAL;
      });
      let sentencia = '';
      if (hayFiltro) {
        sentencia = 'WHERE ' + this.obtenerFiltro(body) + ' ';
        tx.executeSql('SELECT COUNT (*) AS FILTERED FROM productos WHERE '
          + this.obtenerFiltro(body), [], (_tx: any, res: any) => {
            resultado.recordsFiltered = res.rows.item(0).FILTERED;
          });
      }
      tx.executeSql('SELECT * FROM productos ' + sentencia
        + this.obtenerOrden(body) + ' ' + this.obtenerOffsetLimit(body), [], (_tx: any, res: any) => {
          const items: any = [];
          if (res.rows.length > 0) {
            for (let i = 0; res.rows.length > i; i++) {
              items.push({
                id: res.rows.item(i).id,
                activo: res.rows.item(i).activo,
                nombre: res.rows.item(i).nombre,
                precio: res.rows.item(i).precio,
                empresa_id: res.rows.item(i).empresa_id,
                impuesto_id: res.rows.item(i).impuesto_id,
                moneda_id: res.rows.item(i).moneda_id,
                imagenes: []
              });
            }
          }
          resultado.records = items;
        });
    }).then(() => {
      if (!hayFiltro) {
        resultado.recordsFiltered = resultado.recordsTotal;
      }
      this.productos.next(resultado);
    });

    return this.productos.asObservable();
  }

  public obtenerProducto(id: any): Observable<any> {
    this.storage?.executeSql('SELECT * FROM productos WHERE id=?', [id]).then(res => {
      let item: any;
      if (res.rows.length > 0) {
        item = {
          id: res.rows.item(0).id,
          activo: res.rows.item(0).activo,
          nombre: res.rows.item(0).nombre,
          precio: res.rows.item(0).precio,
          empresa_id: res.rows.item(0).empresa_id,
          impuesto_id: res.rows.item(0).impuesto_id,
          moneda_id: res.rows.item(0).moneda_id,
          imagenes: []
        };
        this.storage?.executeSql('SELECT * FROM imagenes WHERE producto_id=?', [id]).then(res => {
          if (res.rows.length > 0) {
            for (let i = 0; res.rows.length > i; i++) {
              item.imagenes.push(
                {
                  id: res.rows.item(i).id,
                  contenido: res.rows.item(i).contenido,
                  nombre: res.rows.item(i).nombre,
                  portada: res.rows.item(i).portada,
                  tamaño: res.rows.item(i).tamaño,
                  tipo: res.rows.item(i).tipo,
                  producto_id: res.rows.item(i).producto_id
                }
              );
            }
          }
        }).finally(() => {
          this.producto.next(item);
        });
      }
    });
    return this.producto.asObservable();
  }
  public obtenerCategorias(): Observable<any> {
    this.storage?.executeSql('SELECT * FROM categorias', []).then(res => {
      const items = [];
      if (res.rows.length > 0) {
        for (let i = 0; res.rows.length > i; i++) {
          items.push({
            id: res.rows.item(i).id,
            activa: res.rows.item(i).activa,
            descripcion: res.rows.item(i).descripcion,
            es_raiz: res.rows.item(i).es_raiz,
            id_padre: res.rows.item(i).id_padre,
            nombre: res.rows.item(i).nombre,
            posicion: res.rows.item(i).posicion,
            empresa_id: res.rows.item(i).empresa_id
          });
        }
      }
      this.categorias.next({
        records: items,
        recordsTotal: items.length,
        recordsFiltered: items.length
      });
    });
    return this.categorias.asObservable();
  }
  public obtenerPortada(id: any): Observable<any> {
    this.storage?.executeSql('SELECT * FROM imagenes WHERE id=?', [id]).then(res => {
      let item;
      if (res.rows.length > 0) {
        item = {
          id: res.rows.item(0).id,
          contenido: res.rows.item(0).contenido,
          nombre: res.rows.item(0).nombre,
          portada: res.rows.item(0).portada,
          tamaño: res.rows.item(0).tamaño,
          tipo: res.rows.item(0).tipo,
          producto_id: res.rows.item(0).producto_id
        };
      }
      this.imagen.next(item);
    });
    return this.imagen.asObservable();
  }

  public crearCliente(values: any): Observable<any> {
    const autorizado = this.authService.autorizadoValue;
    this.storage?.transaction((tx: DbTransaction) => {
      tx.executeSql('INSERT INTO clientes(email, nombre, apellido, documento, rut, ' +
        'razon_social, direccion_fiscal, fecha_desde, vendedor_id, sucursal_id, alta_offline) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
        [values.email, values.nombre, values.apellido, values.documento, values.rut,
        values.razon_social, values.direccion_fiscal, new Date(), autorizado.vendedor_id,
        autorizado.sucursal_id, 1],
        (_tx: any, res: any) => {
          if (values?.direcciones) {
            values.direcciones.forEach((item: any) => {
              tx.executeSql('INSERT INTO direcciones(alias, nombre, apellido, documento, rut, ' +
                'razon_social, telefono, celular, direccion1, direccion2, codigo_postal, otro, cliente_id, ' +
                'id_pais, id_provincia, id_ciudad, id_empresa, alta_offline) ' +
                'VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
                [item.alias, item.nombre, item.apellido, item.documento, item.rut,
                item.razon_social, item.telefono, item.celular, item.direccion1,
                item.direccion2, item.codigo_postal, item.otro, res.insertId,
                item.id_pais, item.id_provincia, item.id_ciudad, item.id_empresa, 1
                ]);
            });
          }
        });
    }).then(() => {
      this.respCrearCliente.next(true);
    }).catch(() => {
      this.respCrearCliente.next(false);
    });
    return this.respCrearCliente.asObservable();
  }
  public editarCliente(id: any, body: any): Observable<any> {
    const autorizado = this.authService.autorizadoValue;
    this.storage?.transaction((tx: DbTransaction) => {
      tx.executeSql('UPDATE clientes SET email =?, nombre=?, apellido=?, documento=?, rut=?, ' +
        'razon_social=?, direccion_fiscal=?, vendedor_id=?, sucursal_id=?, update_offline=? ' +
        'WHERE id=?',
        [body.email, body.nombre, body.apellido, body.documento, body.rut,
        body.razon_social, body.direccion_fiscal, autorizado.vendedor_id,
        autorizado.sucursal_id, 1, id],
        () => {
          if (body?.direcciones) {
            body.direcciones.forEach((item: any) => {
              if (this.isNumeric(item.id)) {
                tx.executeSql('UPDATE direcciones SET alias=?, nombre=?, apellido=?, documento=?, rut=?, ' +
                  'razon_social=?, telefono=?, celular=?, direccion1=?, direccion2=?, codigo_postal=?, otro=?, ' +
                  'id_pais=?, id_provincia=?, id_ciudad=?, id_empresa=?, update_offline=? ' +
                  'WHERE id=?',
                  [item.alias, item.nombre, item.apellido, item.documento, item.rut,
                  item.razon_social, item.telefono, item.celular, item.direccion1,
                  item.direccion2, item.codigo_postal, item.otro,
                  item.id_pais, item.id_provincia, item.id_ciudad, item.id_empresa, item.id
                  ]);
              } else {
                tx.executeSql('INSERT INTO direcciones(alias, nombre, apellido, documento, rut, ' +
                  'razon_social, telefono, celular, direccion1, direccion2, codigo_postal, otro, cliente_id, ' +
                  'id_pais, id_provincia, id_ciudad, id_empresa, alta_offline) ' +
                  'VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
                  [item.alias, item.nombre, item.apellido, item.documento, item.rut,
                  item.razon_social, item.telefono, item.celular, item.direccion1,
                  item.direccion2, item.codigo_postal, item.otro, id,
                  item.id_pais, item.id_provincia, item.id_ciudad, item.id_empresa, 1
                  ]);
              }
            });
          }
        });
    }).then(() => {
      this.respEditarCliente.next(true);
    }).catch(() => {
      this.respEditarCliente.next(false);
    });
    return this.respEditarCliente.asObservable();
  }
  public obtenerCliente(id: any): Observable<any> {
    this.storage?.executeSql('SELECT * FROM clientes WHERE id=?', [id]).then(res => {
      let item: any;
      if (res.rows.length > 0) {
        item = {
          id: res.rows.item(0).id,
          email: res.rows.item(0).email,
          nombre: res.rows.item(0).nombre,
          apellido: res.rows.item(0).apellido,
          direccion_fiscal: res.rows.item(0).direccion_fiscal,
          documento: res.rows.item(0).documento,
          fecha_desde: res.rows.item(0).fecha_desde,
          razon_social: res.rows.item(0).razon_social,
          rut: res.rows.item(0).rut,
          sucursal_id: res.rows.item(0).sucursal_id,
          vendedor_id: res.rows.item(0).vendedor_id,
          direcciones: []
        };
        this.storage?.executeSql('SELECT * FROM direcciones WHERE cliente_id=? AND activo=? AND borrado=?', [id, 1, 0]).then(res => {
          if (res.rows.length > 0) {
            for (let i = 0; res.rows.length > i; i++) {
              item.direcciones.push(
                {
                  id: res.rows.item(i).id,
                  alias: res.rows.item(i).alias,
                  nombre: res.rows.item(i).nombre,
                  apellido: res.rows.item(i).apellido,
                  rut: res.rows.item(i).rut,
                  razon_social: res.rows.item(i).razon_social,
                  telefono: res.rows.item(i).telefono,
                  codigo_postal: res.rows.item(i).codigo_postal,
                  celular: res.rows.item(i).celular,
                  direccion1: res.rows.item(i).direccion1,
                  direccion2: res.rows.item(i).direccion2,
                  cliente_id: id,
                  otro: res.rows.item(i).otro,
                  id_pais: res.rows.item(i).id_pais,
                  id_provincia: res.rows.item(i).id_provincia,
                  id_ciudad: res.rows.item(i).id_ciudad,
                  id_empresa: res.rows.item(i).id_empresa
                }
              );
            }
          }
        }).finally(() => {
          this.cliente.next(item);
        });
      }
    });
    return this.cliente.asObservable();
  }

  public obtenerClientes(body: any): Observable<any> {
    const resultado = {
      records: [],
      recordsTotal: 0,
      recordsFiltered: 0
    };
    const hayFiltro = body.filtro && body.filtro.valor && body.filtro.valor.length > 0;
    this.storage?.transaction((tx: DbTransaction) => {
      tx.executeSql('SELECT COUNT(*) AS TOTAL FROM clientes', [], (_tx: any, res: any) => {
        resultado.recordsTotal = res.rows.item(0).TOTAL;
      });
      let sentencia = '';
      if (hayFiltro) {
        sentencia = 'WHERE ' + this.obtenerFiltro(body) + ' ';
        tx.executeSql('SELECT COUNT (*) AS FILTERED FROM clientes WHERE '
          + this.obtenerFiltro(body), [], (_tx: any, res: any) => {
            resultado.recordsFiltered = res.rows.item(0).FILTERED;
          });
      }
      tx.executeSql('SELECT * FROM clientes ' + sentencia
        + this.obtenerOrden(body) + ' ' + this.obtenerOffsetLimit(body), [], (_tx: any, res: any) => {
          const items: any = [];
          if (res.rows.length > 0) {
            for (let i = 0; res.rows.length > i; i++) {
              items.push({
                id: res.rows.item(i).id,
                email: res.rows.item(i).email,
                nombre: res.rows.item(i).nombre,
                apellido: res.rows.item(i).apellido,
                direccion_fiscal: res.rows.item(i).direccion_fiscal,
                documento: res.rows.item(i).documento,
                fecha_desde: res.rows.item(i).fecha_desde,
                razon_social: res.rows.item(i).razon_social,
                rut: res.rows.item(i).rut,
                sucursal_id: res.rows.item(i).sucursal_id,
                vendedor_id: res.rows.item(i).vendedor_id
              });
            }
          }
          resultado.records = items;
        });
    }).then(() => {
      if (!hayFiltro) {
        resultado.recordsFiltered = resultado.recordsTotal;
      }
      this.clientes.next(resultado);
    });

    return this.clientes.asObservable();
  }

  public obtenerPaises(body: any): Observable<any> {
    const resultado = {
      records: [],
      recordsTotal: 0,
      recordsFiltered: 0
    };
    const autorizado = this.authService.autorizadoValue;
    const hayFiltro = body.filtro && body.filtro.valor && body.filtro.valor.length > 0;
    this.storage?.transaction((tx: DbTransaction) => {
      tx.executeSql('SELECT COUNT(*.p) AS TOTAL FROM empresas_paises ' +
        'INNER JOIN paises p ON pais_id = p.id ' +
        'WHERE empresa_id =? AND p.activo', [autorizado.id_empresa], (_tx: any, res: any) => {
          resultado.recordsTotal = res.rows.item(0).TOTAL;
        });
      let sentencia = 'WHERE ';
      if (hayFiltro) {
        sentencia += this.obtenerFiltro(body, 'p') + ' AND ';
        tx.executeSql('SELECT COUNT(*.p) AS FILTERED FROM empresas_paises ' +
          'INNER JOIN paises p ON pais_id = p.id ' + sentencia +
          'empresa_id =? AND p.activo', [autorizado.id_empresa], (_tx: any, res: any) => {
            resultado.recordsFiltered = res.rows.item(0).FILTERED;
          });
      }
      tx.executeSql('SELECT *.p FROM empresas_paises ' +
        'INNER JOIN paises p ON pais_id = p.id ' + sentencia +
        'empresa_id =? AND p.activo ' +
        + this.obtenerOrden(body, 'p') + ' ' + this.obtenerOffsetLimit(body), [], (_tx: any, res: any) => {
          const items: any = [];
          if (res.rows.length > 0) {
            for (let i = 0; res.rows.length > i; i++) {
              items.push({
                id: res.rows.item(i).id,
                nombre: res.rows.item(i).nombre
              });
            }
          }
          resultado.records = items;
        });
    }).then(() => {
      if (!hayFiltro) {
        resultado.recordsFiltered = resultado.recordsTotal;
      }
      this.paises.next(resultado);
    });

    return this.paises.asObservable();
  }

  public obtenerProvincias(body: any): Observable<any> {
    const resultado = {
      records: [],
      recordsTotal: 0,
      recordsFiltered: 0
    }
    const hayFiltro = body.filtro && body.filtro.valor && body.filtro.valor.length > 0;
    this.storage?.transaction((tx: DbTransaction) => {
      tx.executeSql('SELECT count(*) AS TOTAL FROM provincias ' +
        'WHERE pais_id =? AND p.activo', [body.id_pais], (_tx: any, res: any) => {
          resultado.recordsTotal = res.rows.item(0).TOTAL;
        });
      let sentencia = 'WHERE ';
      if (hayFiltro) {
        sentencia += this.obtenerFiltro(body) + ' AND ';
        tx.executeSql('SELECT COUNT(*) AS FILTERED FROM provincias ' +
          sentencia + 'pais_id =? AND p.activo', [body.id_pais], (_tx: any, res: any) => {
            resultado.recordsFiltered = res.rows.item(0).FILTERED;
          });
      }
      tx.executeSql('SELECT * FROM provincias ' + sentencia +
        'pais_id =? AND p.activo ' + this.obtenerOrden(body) +
        ' ' + this.obtenerOffsetLimit(body), [], (_tx: any, res: any) => {
          const items: any = [];
          if (res.rows.length > 0) {
            for (let i = 0; res.rows.length > i; i++) {
              items.push({
                id: res.rows.item(i).id,
                nombre: res.rows.item(i).nombre
              });
            }
          }
          resultado.records = items;
        });
    }).then(() => {
      if (!hayFiltro) {
        resultado.recordsFiltered = resultado.recordsTotal;
      }
      this.provincias.next(resultado);
    });

    return this.provincias.asObservable();
  }

  public obtenerCiudades(body: any): Observable<any> {
    const resultado = {
      records: [],
      recordsTotal: 0,
      recordsFiltered: 0
    }
    const hayFiltro = body.filtro && body.filtro.valor && body.filtro.valor.length > 0;
    this.storage?.transaction((tx: DbTransaction) => {
      tx.executeSql('SELECT COUNT(*) AS TOTAL FROM ciudades ' +
        'WHERE provincia_id =? AND p.activo', [body.id_provincia], (_tx: any, res: any) => {
          resultado.recordsTotal = res.rows.item(0).TOTAL;
        });
      let sentencia = 'WHERE ';
      if (hayFiltro) {
        sentencia += this.obtenerFiltro(body) + ' AND ';
        tx.executeSql('SELECT COUNT(*) AS FILTERED FROM ciudades ' + sentencia +
          'provincia_id =? AND p.activo', [body.id_provincia], (_tx: any, res: any) => {
            resultado.recordsFiltered = res.rows.item(0).FILTERED;
          });
      }
      tx.executeSql('SELECT * FROM ciudades ' + sentencia +
        'provincia_id =? AND p.activo ' + this.obtenerOrden(body) +
        ' ' + this.obtenerOffsetLimit(body), [], (_tx: any, res: any) => {
          const items: any = [];
          if (res.rows.length > 0) {
            for (let i = 0; res.rows.length > i; i++) {
              items.push({
                id: res.rows.item(i).id,
                nombre: res.rows.item(i).nombre
              });
            }
          }
          resultado.records = items;
        });
    }).then(() => {
      if (!hayFiltro) {
        resultado.recordsFiltered = resultado.recordsTotal;
      }
      this.ciudades.next(resultado);
    });

    return this.ciudades.asObservable();
  }

  public obtenerLocalizacion(id_ciudad: any): Observable<any> {
    const result: any = { selections: {} };
    this.storage?.executeSql('SELECT c.id AS ciudadValue, pr.id AS provinciaValue,' +
      ' p.id AS paisValue, c.nombre AS ciudadLabel, pr.nombre AS provinciaLabel,' +
      ' p.nombre AS paisLabel FROM' +
      ' INNER JOIN provincias pr ON c.provincia_id = pr.id' +
      ' INNER JOIN paises p ON pr.pais_id = p.id' +
      ' ciudades c WHERE c.id=?', [id_ciudad]).then(res => {
        if (res.rows.length > 0) {
          result.selections.id_ciudad = {
            value: res.rows.item(0).ciudadValue,
            label: res.rows.item(0).ciudadLabel
          };
          result.selections.id_provincia = {
            value: res.rows.item(0).provinciaValue,
            label: res.rows.item(0).provinciaLabel
          };
          result.selections.id_pais = {
            value: res.rows.item(0).paisValue,
            label: res.rows.item(0).paisLabel
          };
        }
        this.localizacion.next(result);
      });

    return this.localizacion.asObservable();
  }

  public obtenerDireccion(id: any): Observable<any> {
    this.storage?.executeSql(
      'SELECT * FROM direccion WHERE activo AND NOT borrado AND id =?', [id]).then(res => {
        let item: any;
        if (res.rows.length > 0) {
          item = {
            id: res.rows.item(0).id,
            rut: res.rows.item(0).rut,
            razon_social: res.rows.item(0).razon_social,
            alias: res.rows.item(0).alias,
            celular: res.rows.item(0).celular,
            codigo_postal: res.rows.item(0).codigo_postal,
            direccion1: res.rows.item(0).direccion1,
            direccion2: res.rows.item(0).direccion2,
            id_ciudad: res.rows.item(0).id_ciudad,
            id_pais: res.rows.item(0).id_pais,
            id_provincia: res.rows.item(0).id_provincia,
            otro: res.rows.item(0).otro,
            telefono: res.rows.item(0).telefono
          };
          this.obtenerLocalizacion(item.id_ciudad).subscribe((resLoc: any) => {
            if (resLoc) {
              item.selections = resLoc.selections;
            }
          }, () => {

          }, () => {
            this.direccion.next(item);
          });
        }
      });
    return this.direccion.asObservable();
  }

  public obtenerPedidos(body: any, id_cliente?: any, id_vendedor?: any): Observable<any> {
    const resultado = {
      records: [],
      recordsTotal: 0,
      recordsFiltered: 0
    };
    const autorizado = this.authService.autorizadoValue;
    const hayFiltro = body.filtro && body.filtro.valor && body.filtro.valor.length > 0;
    const data = [autorizado.sucursal_id];
    this.storage?.transaction((tx: DbTransaction) => {
      tx.executeSql('SELECT COUNT(*) AS TOTAL FROM pedidos WHERE sucursal_id = ?', data, (_tx: any, res: any) => {
        resultado.recordsTotal = res.rows.item(0).TOTAL;
      });
      let filtro = 'WHERE sucursal_id = ?';
      if (id_cliente) {
        filtro += ' AND (id_cliente = ? OR id_cliente_offline = ?)';
        data.push(id_cliente);
        data.push(id_cliente);
      }
      if (id_vendedor) {
        filtro += ' AND vendedor_id = ?';
        data.push(id_vendedor);
      }
      let sentencia = filtro + ' ';
      if (hayFiltro) {
        sentencia = this.obtenerFiltro(body) + ' ';
        tx.executeSql('SELECT COUNT (*) AS FILTERED FROM pedidos ' +
          filtro + ' ' + this.obtenerFiltro(body), data, (_tx: any, res: any) => {
            resultado.recordsFiltered = res.rows.item(0).FILTERED;
          });
      }
      tx.executeSql('SELECT * FROM pedidos ' + sentencia
        + this.obtenerOrden(body) + ' ' + this.obtenerOffsetLimit(body), data, (_tx: any, res: any) => {
          const items: any = [];
          if (res.rows.length > 0) {
            for (let i = 0; res.rows.length > i; i++) {
              items.push({
                id: res.rows.item(i).id,
                id_cliente: res.rows.item(i).id_cliente,
                id_cliente_offline: res.rows.item(i).id_cliente_offline,
                id_direccion: res.rows.item(i).id_direccion,
                id_direccion_envio: res.rows.item(i).id_direccion_envio,
                id_forma_pago: res.rows.item(i).id_forma_pago,
                observaciones: res.rows.item(i).observaciones,
                referencia: res.rows.item(i).referencia ?? '',
                id_moneda: res.rows.item(i).id_moneda,
                sucursal_id: res.rows.item(i).sucursal_id,
                vendedor_id: res.rows.item(i).vendedor_id,
                cliente: '',
                total_pago: 0,
                estado_actual: 'offline',
                color_estado_actual: 'grey',
                simbolo_moneda: '',
                fecha_agregado: '',
                detalle: []
              });

              const idCliente = items[i].id_cliente || items[i].id_cliente_offline;
              tx.executeSql('SELECT razon_social FROM clientes' +
                ' WHERE id= ?', [idCliente], (_tx: any, resCli: any) => {
                  if (resCli.rows.length > 0) {
                    items[i].cliente = resCli.rows.item(0).razon_social;
                  }
                });

              tx.executeSql('SELECT pd.cantidad as cantidad, pr.precio as precio' +
                ' FROM pedidos_detalle pd' +
                ' INNER JOIN productos pr ON pd.id_producto = pr.id' +
                ' WHERE pd.id_pedido=?', [items[i].id], (_tx: any, resDet: any) => {
                  if (resDet.rows.length > 0) {
                    for (let j = 0; resDet.rows.length > j; j++) {
                      items[i].total_pago = items[i].total_pago +
                      (resDet.rows.item(j).cantidad * resDet.rows.item(j).precio);
                    }
                  }
                });

            }
          }
          resultado.records = items;
        });

    }).then(() => {
      if (!hayFiltro && !id_vendedor && id_cliente) {
        resultado.recordsFiltered = resultado.recordsTotal;
      }
      this.pedidos.next(resultado);
    });

    return this.pedidos.asObservable();
  }

  public crearPedido(values: any): Observable<any> {
    const autorizado = this.authService.autorizadoValue;
    this.storage?.transaction((tx: DbTransaction) => {
      tx.executeSql('INSERT INTO pedidos(id_cliente, id_cliente_offline, id_direccion, id_direccion_envio, ' +
        'id_forma_pago, id_moneda, sucursal_id, vendedor_id) VALUES (?, ?, ?, ?, ?, ?, ?, ?)',
        [
          values.id_cliente,
          values.id_cliente_offline,
          values.id_direccion,
          values.id_direccion_envio,
          values.id_forma_pago,
          values.id_moneda,
          autorizado.sucursal_id,
          autorizado.vendedor_id
        ], (_tx: any, res: any) => {
          if (values?.pedidoDetalle) {
            values.pedidoDetalle.forEach((item: any) => {
              tx.executeSql('INSERT INTO pedidos_detalle(cantidad, id_deposito, id_producto, id_pedido) ' +
                'VALUES (?, ?, ?, ?)',
                [
                  item.cantidad,
                  item.id_deposito,
                  item.id_producto,
                  res.insertId
                ]);
            });
          }
        });
    })
      .then(() => {
        this.respCrearPedido.next(true);
      }).catch(() => {
        this.respCrearPedido.next(false);
      });
    return this.respCrearPedido.asObservable();
  }

  public obtenerPedido(id: any): Observable<any> {
    let item: any;
    this.storage?.transaction((tx: DbTransaction) => {
      tx.executeSql('SELECT p.* , d.alias as d_alias, d.direccion1 as d_direccion1, d.telefono as d_telefono,' +
        ' de.alias as de_alias, de.direccion1 as de_direccion1, de.telefono as de_telefono,' +
        ' m.moneda as m_moneda, m.simbolo as m_simbolo,' +
        ' f.nombre as f_nombre' +
        ' FROM pedidos p' +
        ' INNER JOIN direcciones d ON p.id_direccion = d.id' +
        ' INNER JOIN direcciones de ON p.id_direccion_envio = de.id' +
        ' INNER JOIN monedas m ON p.id_moneda = m.id' +
        ' INNER JOIN formas_de_pago f ON p.id_forma_pago = f.id' +
        ' WHERE p.id=?', [id], (_tx: any, res: any) => {
          if (res.rows.length > 0) {
            const cliente = res.rows.item(0).cl_razon_social || res.rows.item(0).clo_razon_social;
            item = {
              id: res.rows.item(0).id,
              id_cliente: res.rows.item(0).id_cliente,
              id_cliente_offline: res.rows.item(0).id_cliente_offline,
              id_direccion: res.rows.item(0).id_direccion,
              id_direccion_envio: res.rows.item(0).id_direccion_envio,
              id_forma_pago: res.rows.item(0).id_forma_pago,
              observaciones: res.rows.item(0).observaciones,
              referencia: res.rows.item(0).referencia ?? '',
              id_moneda: res.rows.item(0).id_moneda,
              sucursal_id: res.rows.item(0).sucursal_id,
              vendedor_id: res.rows.item(0).vendedor_id,
              total_pago: 0,
              total_productos: 0,
              estado_actual: 'offline',
              color_estado_actual: 'grey',
              simbolo_moneda: res.rows.item(0).m_simbolo,
              moneda: res.rows.item(0).m_moneda,
              forma_pago: res.rows.item(0).f_nombre,
              direccion: {
                alias: res.rows.item(0).d_alias,
                direccion1: res.rows.item(0).d_direccion1,
                telefono: res.rows.item(0).d_telefono,
              },
              direccion_envio: {
                alias: res.rows.item(0).de_alias,
                direccion1: res.rows.item(0).de_direccion1,
                telefono: res.rows.item(0).de_telefono,
              },
              fecha_agregado: '',
              pedidoDetalle: []
            };
          }
          tx.executeSql('SELECT pd.*, pr.nombre as pr_nombre, pr.precio as pr_precio' +
            ' FROM pedidos_detalle pd' +
            ' INNER JOIN productos pr ON pd.id_producto = pr.id' +
            ' WHERE pd.id_pedido=?', [id], (_tx: any, resDet: any) => {
              if (resDet.rows.length > 0) {
                for (let i = 0; resDet.rows.length > i; i++) {
                  const cantidad = resDet.rows.item(i).cantidad;
                  const precio = resDet.rows.item(i).pr_precio;
                  item.pedidoDetalle.push(
                    {
                      id: resDet.rows.item(i).id,
                      cantidad,
                      id_deposito: resDet.rows.item(i).id_deposito,
                      id_producto: resDet.rows.item(i).id_producto,
                      id_pedido: resDet.rows.item(i).id_pedido,
                      item: {
                        nombre: resDet.rows.item(i).pr_nombre,
                        precio,
                      }
                    });
                  item.total_pago = item.total_pago + (precio * cantidad);
                  tx.executeSql('SELECT contenido FROM imagenes' +
                    ' WHERE producto_id=? AND portada= ?', [resDet.rows.item(i).id_producto, 1], (_tx: any, resImg: any) => {
                      if (resImg.rows.length > 0) {
                        item.pedidoDetalle[i].item.imgPortada = {
                          contenido: resImg.rows.item(0).contenido
                        };
                      }
                    });
                }
              }
            });
          const idCliente = item.id_cliente || item.id_cliente_offline;
          tx.executeSql('SELECT razon_social FROM clientes' +
            ' WHERE id= ?', [idCliente], (_tx: any, resCli: any) => {
              if (resCli.rows.length > 0) {
                item.cliente = resCli.rows.item(0).razon_social;
              }
            });
        });
    }).then(() => {
      this.pedido.next(item);
    }).catch(() => {
      this.pedido.next(false);
    });
    return this.pedido.asObservable();
  }

  public obtenerVendedores(): Observable<any> {
    const autorizado = this.authService.autorizadoValue;
    const items: any = [];
    this.storage?.executeSql('SELECT * FROM colaboradores c ' +
      'LEFT JOIN sucursal_colaborador sc ON c.id = sc.colaborador_id ' +
      'LEFT JOIN login l ON c.email = l.email ' +
      'WHERE l.tipo_usuario = ? AND sc.sucursal_id = ?' +
      'ORDER BY c.id ASC',
      ['VENDEDOR', autorizado.sucursal_id]).then(res => {
        if (res.rows.length > 0) {
          for (let i = 0; res.rows.length > i; i++) {
            items.push({
              id: res.rows.item(i).id,
              nombre: res.rows.item(i).nombre,
              apellido: res.rows.item(i).apellido,
              email: res.rows.item(i).email,
              nombre_completo: res.rows.item(i).nombre + ' ' + res.rows.item(i).apellido
            });
          }
        }
      }).finally(() => {
        this.selectVendedores.next({
          records: items,
          recordsTotal: 0,
          recordsFiltered: 0
        });
      });
    return this.selectVendedores.asObservable();
  }

  public obtenerFormasDePago(): Observable<any> {
    const autorizado = this.authService.autorizadoValue;
    const items: any = [];
    this.storage?.executeSql('SELECT * FROM formas_de_pago ' +
      'WHERE empresa_id IS NULL OR empresa_id = ?' +
      'ORDER BY id ASC',
      [autorizado.id_empresa]).then(res => {
        if (res.rows.length > 0) {
          for (let i = 0; res.rows.length > i; i++) {
            items.push({
              id: res.rows.item(i).id,
              nombre: res.rows.item(i).nombre
            });
          }
        }
      }).finally(() => {
        this.selectFormasDePagos.next({
          records: items,
          recordsTotal: 0,
          recordsFiltered: 0
        });
      });
    return this.selectFormasDePagos.asObservable();
  }

  public obtenerMonedas(): Observable<any> {
    const autorizado = this.authService.autorizadoValue;
    const items: any = [];
    this.storage?.executeSql('SELECT m.id, m.moneda FROM monedas m ' +
      'LEFT JOIN empresas_monedas em ON m.id = em.moneda_id ' +
      'WHERE em.empresa_id = ?' +
      'ORDER BY m.id ASC',
      [autorizado.id_empresa]).then(res => {
        if (res.rows.length > 0) {
          for (let i = 0; res.rows.length > i; i++) {
            items.push({
              id: res.rows.item(i).id,
              moneda: res.rows.item(i).moneda
            });
          }
        }
      }).finally(() => {
        this.selectMonedas.next({
          records: items,
          recordsTotal: 0,
          recordsFiltered: 0
        });
      });
    return this.selectMonedas.asObservable();
  }

  public obtenerEstados(): Observable<any> {
    const autorizado = this.authService.autorizadoValue;
    const items: any = [];
    this.storage?.executeSql('SELECT * FROM estados ' +
      'WHERE empresa_id IS NULL OR empresa_id = ?' +
      'ORDER BY id ASC',
      [autorizado.id_empresa]).then(res => {
        if (res.rows.length > 0) {
          for (let i = 0; res.rows.length > i; i++) {
            items.push({
              id: res.rows.item(i).id,
              nombre: res.rows.item(i).nombre
            });
          }
        }
      }).finally(() => {
        this.selectEstados.next({
          records: items,
          recordsTotal: 0,
          recordsFiltered: 0
        });
      });
    return this.selectEstados.asObservable();
  }

  public obtenerClientesVendedor(body: any): Observable<any> {
    const resultado = {
      records: [],
      recordsTotal: 0,
      recordsFiltered: 0
    };
    const autorizado = this.authService.autorizadoValue;
    const items: any = [];
    const data = [autorizado.sucursal_id, autorizado.vendedor_id];
    this.storage?.transaction((tx: DbTransaction) => {
      tx.executeSql('SELECT COUNT(*) AS TOTAL FROM clientes c ' +
        'WHERE c.fecha_hasta IS NULL AND c.sucursal_id = ? ' +
        'AND (c.vendedor_id IS NULL OR c.vendedor_id = ?)', data, (_tx: any, res: any) => {
          resultado.recordsTotal = res.rows.item(0).TOTAL;
        });
      tx.executeSql('SELECT c.id, c.razon_social FROM clientes c ' +
        'WHERE c.fecha_hasta IS NULL AND c.sucursal_id = ? ' +
        'AND (c.vendedor_id IS NULL OR c.vendedor_id = ?) ' +
        this.obtenerOrden(body) + ' ' + this.obtenerOffsetLimit(body), data, (_tx: any, res: any) => {
          if (res.rows.length > 0) {
            for (let i = 0; res.rows.length > i; i++) {
              items.push({
                id: res.rows.item(i).id,
                razon_social: res.rows.item(i).razon_social,
              });
            }
          }
          resultado.records = items;
        });
    }).then(() => {
      resultado.recordsFiltered = resultado.recordsTotal;
      this.selectClientes.next(resultado);
    });
    return this.selectClientes.asObservable();
  }

  private isNumeric(str: any): boolean {
    if (typeof str !== 'string') { return false; }
    return !Number.isNaN(str) &&
      !Number.isNaN(parseFloat(str));
  }

  private obtenerFiltro(body: any, alias?: string): string {
    let filtro = '';
    const aliasStr = alias ? (alias + '.') : '';
    if (body.filtro && body.filtro.valor && body.filtro.valor.length > 0) {
      for (let i = 0; i < body.filtro.campos.length; i++) {
        const item = 'UPPER(' + aliasStr + body.filtro.campos[i] + ') LIKE \'%' + body.filtro.valor.toUpperCase() + '%\'';
        if (i === 0) {
          filtro += item;
        } else {
          filtro += ' OR ' + item;
        }
      }
    }
    return filtro;
  }

  private obtenerOrden(body: any, alias?: string): string {
    let orden = '';
    const aliasStr = alias ? (alias + '.') : '';
    if (body.orden) {
      orden = 'ORDER BY ' + aliasStr + body.orden.campo + ' ' + body.orden.direccion;
    }
    return orden;
  }

  private obtenerOffsetLimit(body: any): string {
    let limit = 10;
    let offset = 0;
    if (body.limit && body.limit > 0) {
      limit = body.limit;
    }
    if (body.offset && body.offset > -1) {
      offset = body.offset;
    }
    return 'LIMIT ' + limit + ' OFFSET ' + offset;
  }

}
