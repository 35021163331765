import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Ng2ImgMaxService } from 'ng2-img-max';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html'
})
export class ImageComponent implements OnInit {

@ViewChild('fileInput', {static: false}) fileInput: ElementRef | undefined;
srcImage: SafeUrl | undefined;
accept = '*';
uploadedImage: File | undefined;
imagePreview: string | undefined;
private allowedExtensions: string[] | undefined;

  constructor(private ng2ImgMax: Ng2ImgMaxService, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.allowedExtensions = ['.jpe', '.jpg', '.jpeg', '.png'];
    this.accept = this.allowedExtensions.join();
  }

  updateField(ev: any): void {
    const image = ev.dataTransfer ? ev.dataTransfer.files[0] : ev.target.files[0];
    this.ng2ImgMax.resizeImage(image, 1000, 1000).subscribe(
      result => {
        this.uploadedImage = new File([result], result.name);
        this.getImagePreview(ev);
      }
    );
  }

  private getImagePreview(ev: any): void {
    const reader = new FileReader();
    reader.onloadend = () => {
      const buff = Array.from(new Uint8Array(reader.result as ArrayBuffer));
      const result = btoa(buff.map((item) => String.fromCharCode(item)).join(''));
      this.imagePreview = result;
      ev.srcElement.setAttribute('value', null);
    };
    if (typeof this.uploadedImage === 'undefined') {
      this.cleanField(ev);
    } else {
      reader.readAsArrayBuffer(this.uploadedImage);
    }
  }

  private cleanField(event?: any): void {
    if (typeof this.fileInput !== 'undefined' && typeof this.fileInput.nativeElement !== 'undefined') {
      this.fileInput.nativeElement.value = '';
    }
    if (typeof event !== 'undefined' && typeof event.srcElement !== 'undefined') {
      event.srcElement.setAttribute('value', null);
    }
  }

}
