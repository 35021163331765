import { HttpClient } from '@angular/common/http';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { EnvService } from 'src/app/env.service';
import { UtilsService } from 'src/app/utils.service';
import { AuthService } from '../autorization/auth.service';
import { SwitchService } from '../switch/switch.service';
import { ApiService } from './api.service';
import { DataService } from './data.service';
import { DbService } from './db.service';

const dataServiceFactory = (
    http: HttpClient,
    env: EnvService,
    switchService: SwitchService,
    utilsService: UtilsService,
    platform: Platform,
    sqlite: SQLite,
    authService: AuthService) => {
        const idSwitch = switchService.obtenerIdOnline();
        const isMobile = utilsService.isMobilePlatform();
        if (!isMobile || switchService.isSwitchOn(idSwitch)) {
          return new ApiService(http, env);
        } else {
          return new DbService(platform, sqlite, authService);
        }
  };

export let dataServiceProvider = {
    provide: DataService,
    useFactory: dataServiceFactory,
    deps: [
        HttpClient,
        EnvService,
        SwitchService,
        UtilsService,
        Platform,
        SQLite,
        AuthService
    ]
  };
