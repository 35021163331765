import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvService } from 'src/app/env.service';
import { DataService } from './data.service';

export class ApiService extends DataService {

  private isReady: BehaviorSubject<any> = new BehaviorSubject(true);

  constructor(private http: HttpClient, private env: EnvService) {
    super();
  }

  public obtenerProducto(id: any): Observable<any> {
    return this.http.get(this.env.apiRest + '/producto/' + id);
  }

  public obtenerProductos(body: any): Observable<any> {
    return this.http.post(this.env.apiRest + '/catalogo/listar-productos-por-categoria', body);
  }

  public obtenerCategorias(): Observable<any> {
    return this.http.get(this.env.apiRest + '/categoria/listar');
  }

  public obtenerPortada(id: any): Observable<any> {
    return this.http.get(this.env.apiRest + '/producto/portada/' + id);
  }

  public obtenerClientes(body: any): Observable<any> {
    return this.http.post(this.env.apiRest + '/cliente/listar-clientes-vendedor', body);
  }

  public obtenerCliente(id: any): Observable<any> {
    return this.http.get(this.env.apiRest + '/cliente/' + id);
  }

  public crearCliente(body: any): Observable<any> {
    delete body.id;
    return this.http.put(this.env.apiRest + '/cliente/crear', body);
  }

  public editarCliente(id: any, body: any): Observable<any> {
    delete body.id;
    return this.http.post(this.env.apiRest + '/cliente/editar/' + id, body);
  }

  public crearPedido(body: any): Observable<any> {
    delete body.id;
    return this.http.post(this.env.apiRest + '/catalogo/crear-pedido', body);
  }

  public obtenerPedido(id: any): Observable<any> {
    return this.http.get(this.env.apiRest + '/catalogo/pedido/' + id);
  }

  public obtenerPedidos(body: any, _id_cliente?: any, _id_vendedor?: any): Observable<any> {
    return this.http.post(this.env.apiRest + '/catalogo/listar-pedidos', body);
  }

  public obtenerLocalizacion(idCiudad: any): Observable<any> {
    return this.http.get(this.env.apiRest + '/direccion/localizacion/' + idCiudad);
  }

  public obtenerPaises(body: any): Observable<any> {
    return this.http.post(this.env.apiRest + '/direccion/paises',
      body);
  }

  public obtenerProvincias(body: any): Observable<any> {
    return this.http.post(this.env.apiRest + '/direccion/provincias',
      body);
  }

  public obtenerCiudades(body: any): Observable<any> {
    return this.http.post(this.env.apiRest + '/direccion/ciudades',
      body);
  }

  public obtenerDireccion(id: any): Observable<any> {
    return this.http.get(this.env.apiRest + '/direccion/' + id);
  }

  public obtenerVendedores(): Observable<any> {
    return this.http.get(this.env.apiRest + '/cliente/listar-vendedores');
  }

  public obtenerFormasDePago(): Observable<any> {
    return this.http.get(this.env.apiRest + '/catalogo/listar-formas-pago/');
  }

  public obtenerMonedas(): Observable<any> {
    return this.http.get(this.env.apiRest + '/empresa/monedas');
  }

  public obtenerEstados(): Observable<any> {
    return this.http.get(this.env.apiRest + '/pedido/listar-estados');
  }

  public obtenerClientesVendedor(body: any): Observable<any> {
    return this.http.post(this.env.apiRest + '/cliente/listar-clientes-vendedor', body);
  }

  public isDataReady(): Observable<boolean> {
    return this.isReady.asObservable();
  }

}
