import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  constructor(private elementRef: ElementRef, private render: Renderer2) { }

  @Input() delay: number = 350;
  @Input() position: string = 'left';
  @Input() tooltip: string | undefined;

  targetElement: JQuery | undefined;

  ngOnInit(): void {
    this.initElements();
  }

  initElements() {
    this.targetElement = $(this.elementRef.nativeElement);
  }

  ngAfterViewInit() {
    this.initTooltip();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.targetElement) {
      this.initTooltip();
    }
  }

  ngOnDestroy() {
  }

  initTooltip() {
    const tooltipOptions = {
      delay: isNaN(this.delay) || this.delay == null ? 350 : this.delay,
      position: this.position || 'left',
      html: this.tooltip,
    };
    (this.targetElement as any)['tooltip'].apply(this.targetElement, [tooltipOptions]);
    setTimeout(() => {
        ($('.tooltipped') as any)?.tooltip('close');
    }, 500);
  }

}
