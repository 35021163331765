<!DOCTYPE html>
<table datatable [dtOptions]="dtOptions" class="table dtr-inline" [id]="dtId"
[ngClass]="classTable"></table>
<div *ngIf="crear" class="row mt-2">
    <div class="col s12 right-align" [ngClass]="dtId">
        <a (click)="crearItem()" 
        class="btn-floating cyan waves-effect waves-cyan btn-crear-item">
            <i class="material-icons">add</i>
        </a>
    </div>
</div>