import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documento'
})
export class DocumentoPipe implements PipeTransform {

  transform(value: any): any {
    if (value && value.toString().length > 0) {
    const valStr = value.toString().match(/\d/g).join('');
    if (!isNaN(valStr) && valStr.length > 0) {
      const digito = valStr.substring(valStr.length - 1);
      const reverse = this.reverseStringToArray(valStr.substring(0, valStr.length - 1));
      let i = 0;
      const resultado = [];
      while (i < reverse.length) {
        resultado.push(reverse[i]);
        i++;
        if ((i % 3 ) === 0 && i < reverse.length) {
          resultado.push('.');
        }
      }
      let documento = this.reverseArrayToString(resultado);
      if (reverse.length > 0) {
        documento = documento.concat('-', digito);
      } else {
        documento = documento.concat(digito);
      }
      return documento;
    }
    return valStr;
  }
    return value;
  }

  private reverseStringToArray(str: any): any {
    return str.split('').reverse();
  }

  private reverseArrayToString(arr: any[]): any {
    return arr.reverse().join('');
  }

}
