import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private invalidException = 'java.security.InvalidParameterException:';
  private storage!: SQLiteObject;
  private isReady: BehaviorSubject<any> = new BehaviorSubject(false);
  private subjectIsMobile: any;

  constructor(private platform: Platform, private sqlite: SQLite,
              private httpClient: HttpClient, private sqlPorter: SQLitePorter, private router: Router) {
    this.platform.ready().then(() => {
      if (this.isMobilePlatform()) {
        this.sqlite.create({
          name: 'mayoristas.db',
          location: 'default'
        })
          .then((db: SQLiteObject) => {
            this.storage = db;
            this.createDB();
          });
      }
    });
  }

  handleBackendErrors(response: any, textoExito?: any, redirect?: any): boolean {
    response = !response ? {error: 'Ocurrió un error interno'} : response;
    const result = response?.error;
    result ? this.alertaError({ title: 'Error', message: response.error }) :
      this.alertaExito(textoExito, redirect);
    return result;
  }

  handleHttpError(response: any): void {
    if (response instanceof HttpErrorResponse) {
      const errorObj = {
        title: response.statusText,
        message: response.message,
      };
      if (response?.error?.error) {
        const message = response.error.error.replace(this.invalidException, '');
        errorObj.message = message.trim();
        errorObj.title = 'Error';
      }
      this.alertaError(errorObj);
    }
  }

  alertaError(errorObj: any): void {
    const title = errorObj.title;
    const message = errorObj.message;
    Swal.fire({
      title,
      text: message,
      icon: 'error',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Aceptar',
      cancelButtonColor: '#3f51b5',
      allowOutsideClick: false,
      focusCancel: true,
    }).then(() => { });
  }

  alertaExito(texto: any, redirect?: any): void {
    if (texto) {
      Swal.fire({
        title: texto,
        icon: 'success',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Aceptar',
        cancelButtonColor: '#3f51b5',
        allowOutsideClick: false,
        focusCancel: true,
      }).then(() => {
        if (redirect) {
          this.router.navigate(redirect);
        }
       });
    }
  }

  tooltip(exp: any, text: any, position?: any): void {
    const ele: any = $(exp);
    if (ele) {
      ele.tooltip({
        html: text,
        position: position || 'left'
      });
    }
  }

  public getStorageDB(): SQLiteObject {
    return this.storage;
  }

  public isDBReady(): Observable<boolean> {
    return this.isReady.asObservable();
  }

  getSubjectIsMobile(): Observable<any> {
    if (!this.subjectIsMobile) {
      this.subjectIsMobile = new BehaviorSubject<any>(false);
    }
    return this.subjectIsMobile.asObservable();
  }

  setSubjectIsMobile(value: boolean): void {
    if (!this.subjectIsMobile) {
      this.subjectIsMobile = new BehaviorSubject<any>(value);
    } else {
      return this.subjectIsMobile.next(value);
    }
  }

  isMobilePlatform(): boolean {
    let isMobile = false;
    if ((window as any).cordova) {
      isMobile = true;
    } else {
      isMobile = this.platform.is('ios')
        || this.platform.is('android')
        && !(this.platform.is('desktop') || this.platform.is('mobileweb'));
    }
    this.setSubjectIsMobile(isMobile);
    return isMobile;
  }

  private createDB(): void {
    this.httpClient.get('./assets/bd/dump.sql', { responseType: 'text' }).subscribe(
      data => {
        this.sqlPorter.importSqlToDb(this.storage, data).then(_ => {
          this.isReady.next(true);
        });
      }
    );
  }

  public loadDataInit(dataInit: BehaviorSubject<any>): void {
    this.loadMonedas();
    this.loadPaises().subscribe(
      dataPaises => {
        this.sqlPorter.importJsonToDb(this.storage, dataPaises).then(() => {
          this.loadProvincias().subscribe(
            dataProv => {
              this.sqlPorter.importJsonToDb(this.storage, dataProv).then(() => {
                this.loadCiudades().subscribe(
                  dataCiu => {
                    this.sqlPorter.importJsonToDb(this.storage, dataCiu).then(() => {
                      dataInit.next(true);
                    });
                  }
                );
              });
            }
          );
          this.loadImpuestos();
        });
      }
    );
  }


  private loadPaises(): Observable<any> {
    return this.httpClient.get('./assets/bd/paises.json');
  }

  private loadCiudades(): Observable<any> {
    return this.httpClient.get('./assets/bd/ciudades.json');
  }

  private loadProvincias(): Observable<any> {
    return this.httpClient.get('./assets/bd/provincias.json');
  }

  private loadImpuestos(): void {
    this.httpClient.get('./assets/bd/impuestos.json').subscribe(
      data => {
        this.sqlPorter.importJsonToDb(this.storage, data).then(() => {
        });
      }
    );
  }

  private loadMonedas(): void {
    this.httpClient.get('./assets/bd/monedas.json').subscribe(
      data => {
        this.sqlPorter.importJsonToDb(this.storage, data).then(() => {
        });
      }
    );
  }

}
