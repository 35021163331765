import { Component, OnInit, Renderer2, AfterViewInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { UtilsService } from 'src/app/utils.service';
import { TableService } from './table.service';
import * as dataTable from './dataTable-settings';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  @Input() opciones: any;
  @Input() detalle = false;
  @Input() crear = false;
  @Input() tooltipCrear: string | undefined;
  @Input() classTable: string = '';
  @Input() btnDetalle = true;
  @Input() btnEditar = true;
  @Input() btnEliminar = true;
  @Output() eventCrear = new EventEmitter<any>();
  @Output() eventVer = new EventEmitter<any>();
  @Output() eventEditar = new EventEmitter<any>();
  @Output() eventEliminar = new EventEmitter<any>();
  dtOptions: dataTable.DataTableSettingsExt = {};
  dtId: any;
  constructor(private renderer: Renderer2, private utilsService: UtilsService, protected tableService: TableService) { }

  ngOnInit(): void {
    this.dtId = this.tableService.getIdTable();
    this.dtOptions = {
      language: {
        url: '../../../assets/i18n/spanish.json'
      },
      rowId: 'id',
      fixedHeader: true,
      responsive: true,
      retrieve: true,
      drawCallback: this.drawTooltips.bind(this)
    };
    Object.assign(this.dtOptions, this.opciones);
    const hayBotones = this.btnDetalle || this.btnEditar || this.btnEliminar;
    if (this.dtOptions.columns && !this.detalle && hayBotones) {
      this.dtOptions.columns.push(
        {
          data: 'id',
          responsivePriority: 1,
          render: (data: any) => {
            return '<div class="flex right-align">' +
            this.btnHtmlDetalle(data) +
            this.btnHtmlEditar(data) +
            this.btnHtmEliminar(data) + '</div>';
          }, orderable: false
        }
      );
    }
  }

  ngAfterViewInit(): void {
    const id = '#' + this.dtId;
    if (this.tooltipCrear) {
      this.utilsService.tooltip('.' + this.dtId + ' .btn-crear-item', this.tooltipCrear);
    }
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.hasAttribute('view-id')) {
        this.eventVer.emit(event.target.getAttribute('view-id'));
      }
      if (event.target.hasAttribute('edit-id')) {
        this.eventEditar.emit(event.target.getAttribute('edit-id'));
      }
      if (event.target.hasAttribute('delete-id')) {
        this.eventEliminar.emit(event.target.getAttribute('delete-id'));
      }
    });
  }

  obtenerRows(): any[] {
    const table = $('#' + this.dtId).DataTable();
    const data = table.rows().data();
    const rows: any[] = [];
    data.each((ele) => {
      rows.push(ele);
    });
    return rows;
  }

  obtenerRow(id: any): any {
    const table = $('#' + this.dtId).DataTable();
    return table.row('#' + id).data();
  }

  remplazarRows(rows: any[]): void {
    const table = $('#' + this.dtId).DataTable();
    table.rows().remove();
    table.rows.add(rows).draw(false);
  }

  agregarRows(keysCol: string[], rows: any[]): void {
    for (const row of rows) {
      this.agregarRow(keysCol, row);
    }
  }

  agregarRow(keysCol: string[], row: any): void {
    const table = $('#' + this.dtId).DataTable();
    if (!row.id){
      row.id = this.tableService.getId();
    }
    const data = table.rows().data();
    const rows: any[] = [];
    data.each((ele) => {
      rows.push(ele);
    });
    this.tableService.completeValues(keysCol, row);
    table.rows().remove();
    rows.push(row);
    table.rows.add(rows).draw(false);
  }

  editarRow(id: any, row: any): void {
    const table = $('#' + this.dtId).DataTable();
    table.row('#' + id).data(row).draw(false);
  }

  eliminarRow(id: any): void {
    const table = $('#' + this.dtId).DataTable();
    table.rows('#' + id).remove();
    const data = table.rows().data();
    const rows: any[] = [];
    data.each((ele) => {
      rows.push(ele);
    });
    table.rows().remove();
    table.rows.add(rows).draw(false);
  }

  limpiar(rows: any): void {
    setTimeout(() => {
      const table = $('#' + this.dtId).DataTable();
      table.rows().remove();
    });
    setTimeout(() => {
      const table = $('#' + this.dtId).DataTable();
      table.rows.add(rows).draw(false);
    }, 250);
  }

  reload(): void {
    const table = $('#' + this.dtId).DataTable();
    table.ajax.reload();
  }

  draw(len?: number): void {
    const table = $('#' + this.dtId).DataTable();
    if (len) {
      table.page.len(len).draw();
    } else {
      table.draw();
    }
  }

  crearItem(): void {
    this.eventCrear.emit();
  }

  private drawTooltips(): void {
    const id = '#' + this.dtId;
    this.tooltipVer(id);
    this.tooltipEditar(id);
    this.tooltipEliminar(id);
  }

  private tooltipVer(id: any): void {
    this.utilsService.tooltip(id + ' .tabla-ver', 'Ver');
  }

  private tooltipEditar(id: any): void {
    this.utilsService.tooltip(id + ' .tabla-editar', 'Editar');
  }

  private tooltipEliminar(id: any): void {
    this.utilsService.tooltip(id + ' .tabla-eliminar', 'Eliminar');
  }

  private btnHtmlDetalle(data: any): string {
    let html = '';
    if (this.btnDetalle) {
      html = '<a view-id="' + data + '" ' +
        'class="pointer mr-4 tabla-ver"><i ' +
        'view-id="' + data + '" ' +
        'class="material-icons grey-text">remove_red_eye</i></a>';
    }
    return html;
  }

  private btnHtmlEditar(data: any): string {
    let html = '';
    if (this.btnEditar) {
      html = '<a edit-id="'  + data + '" ' +
      'class="pointer mr-4 tabla-editar"><i ' +
      'edit-id="'  + data + '" ' +
      'class="material-icons">edit</i></a>';
    }
    return html;
  }

  private btnHtmEliminar(data: any): string {
    let html = '';
    if (this.btnEliminar) {
      html = '<a class="pointer tabla-eliminar" delete-id="'  + data + '">' +
      '<i delete-id="'  + data + '" ' +
      'class="material-icons red-text">delete</i></a>';
    }
    return html;
  }

  ngOnDestroy(): void {
    $('.material-tooltip').remove();
  }

}
