import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilsService } from 'src/app/utils.service';
import { MzMediaService } from '../media/media.service';
import { SwitchService } from './switch.service';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html'
})
export class SwitchComponent implements OnInit {

  @Input() idSwitch: string | undefined;
  @Input() classSwitch: string = '';
  @Input() onTexto: string | undefined;
  @Input() offTexto: string | undefined;
  @Input() on: boolean = true;
  @Input() header: boolean = true;
  greatMedium: Observable<boolean> | undefined;
  small: Observable<boolean> | undefined;
  @Output() eventChange = new EventEmitter<any>();
  subjectIsMobile = new BehaviorSubject(false);
  isMobile: Observable<boolean> | undefined;

  constructor(private switchService: SwitchService, private mediaService: MzMediaService,
    private utilsService: UtilsService) {
    this.greatMedium = this.mediaService.isActive('gt-m');
    this.small = this.mediaService.isActive('s');
   }

  ngOnInit(): void {
    this.isMobile = this.utilsService.getSubjectIsMobile();
    this.idSwitch = this.switchService.obtenerIdOnline();
    this.switchService.setSubjectSwitch(this.idSwitch, this.on);
  }

  setSwitch(ev: any, idSwitch: any) {
    this.on = ev?.target?.checked;
    this.eventChange.emit({ value: this.on, id: idSwitch });
    this.switchService.setSubjectSwitch(this.idSwitch, this.on);
  }

}
