import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BreadcrumbsService } from '../breadcrumbs/breadcrumbs.service';
import { MzMediaService } from '../media/media.service';
import { SwitchService } from '../switch/switch.service';
import { MenuService } from './menu.service';
import Swal from 'sweetalert2';
import { Platform } from '@ionic/angular';
import { UtilsService } from 'src/app/utils.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {

  menu: Observable<any> | undefined;
  pathActive: string | undefined;
  pathSincro = '/sincronizar';
  isMobile = false;
  isOnline = true;
  private greatMedium: boolean | undefined;
  private unsubscribe = new Subject<any>();

  constructor(private router: Router, private menuService: MenuService, private breadcrumbsService: BreadcrumbsService,
              private mediaService: MzMediaService, private switchService: SwitchService,
              private platform: Platform, private utilsService: UtilsService) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((ev) => {
        this.setActiveMenu(ev);
      });
  }

  setActiveMenu(ev: any): void {
    const path = ev.url;
    this.pathActive = path.replace(/[0-9]/g, '');
  }

  ngAfterViewInit(): void {
    const sidenav: any = $('#slide-out');
    if (sidenav) {
      sidenav.sidenav({
        onOpenEnd: () => {
          const sidenavOverlay = $('.sidenav-overlay');
          sidenavOverlay.each((i, ele) => {
            if (ele.style.display === 'block') {
              ele.classList.add('l-260');
            }
          });
        },
        onCloseStart: () => {
          const sidenavOverlay: any = $('.sidenav-overlay');
          if (sidenavOverlay) {
            $(sidenavOverlay).removeClass('l-260');
          }
        }
      });
    }
    const collapsible: any = $('.collapsible');
    if (collapsible) {
      collapsible.collapsible();
    }
  }

  ngOnInit(): void {
    this.menu = this.menuService.getSubjectMenu();
    this.mediaService.isActive('gt-m').pipe(takeUntil(this.unsubscribe)).subscribe(active => {
      this.greatMedium = active;
    });
    this.platform.ready().then(() => {
      this.isMobile = this.utilsService.isMobilePlatform();
    });
    const idSwitch = this.switchService.obtenerIdOnline();
    this.isOnline = this.switchService.isSwitchOn(idSwitch);
  }

  closeSideNav(): void {
    const sidenav = $('#slide-out');
    if (sidenav && !this.greatMedium) {
      (sidenav as any).sidenav('close');
    }
  }

  navRouteLink(path: any): void {
    const linkPath = this.getRouteLink(path);
    this.closeSideNav();
    if (this.isOnline) {
      this.router.navigate(linkPath);
    } else {
      if (linkPath.length > 1) {
        if (this.breadcrumbsService.isMenuOffline(linkPath[0] + linkPath[1])) {
          this.router.navigate(linkPath);
        } else {
          this.alertaMenuOffline();
        }
      } else {
        this.router.navigate(linkPath);
      }
    }
  }

  descargarAPK(): void {
    Swal.fire({
      title: 'Obteniendo aplicación mobile',
      icon: 'info',
      didOpen: () => {
        Swal.showLoading();
        this.menuService.obtenerAPK().subscribe((blobContent: any) => {
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blobContent);
          a.href = url;
          a.download = 'Casa Bisio.apk';
          a.click();
          a.remove();
        }, (error: any) => {
          this.utilsService.alertaError({ title: 'Error', message: JSON.stringify(error) });
        },
          () => {
            Swal.close();
          });
      }
    }).then(() => {
    });
  }

  private getRouteLink(path: any): string[] {
    const route = path.split('/');
    if (!route[0] || 0 === route[0].length) {
      route[0] = '/';
    } else {
      route.unshift('/');
    }
    return route;
  }

  private alertaMenuOffline(): void {
    const title = 'Desconectado';
    const message = 'Sin conexión no se puede acceder a este menú';
    Swal.fire({
      title,
      text: message,
      icon: 'info',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Aceptar',
      cancelButtonColor: '#3f51b5',
      allowOutsideClick: false,
      focusCancel: true,
    }).then(() => { });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
