<!DOCTYPE html>
<ng-container *ngIf="!(autorizado | async)">
    <div class="row">
        <div class="col s12">
            <div class="container">
                <div id="login-page" class="row">
                    <div class="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card">
                        <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate autocomplete="false">
                            <div class="row">
                                <div [ngClass]="{'s12': !isMobile || true, 's6': isMobile}"
                                class="input-field col center-align">
                                    <img class="responsive-img" style="height: 64px;padding: 10px 0;"
                                        src="./../../../assets/img/Casa_Bisio_Logo.png" alt="casa bisio logo">
                                </div>
                                <div *ngIf="isMobile"
                                class="input-field col s6 center-align height-56">
                                    <div class="row switch-login mb-2">
                                        <app-switch [on]="isOnline" [idSwitch]="'idOnline'"
                                            [header]="false" (eventChange)="setIsOnline($event)"></app-switch>
                                    </div>
                                    <div class="row switch-login">
                                        <label>
                                            {{isOnline ? 'En línea' : 'Desconectado'}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row margin">
                                <div class="input-field col s12">
                                    <i class="material-icons prefix pt-2">person_outline</i>
                                    <input id="email" type="email" formControlName="email"
                                        [ngClass]="{'invalid': mensajes.email}">
                                    <label for="email" class="center-align">Email</label>
                                    <span class="helper-text worng">
                                        {{ mensajes.email }}
                                    </span>
                                </div>
                            </div>
                            <div class="row margin">
                                <div class="input-field col s12">
                                    <i class="material-icons prefix pt-2">lock_outline</i>
                                    <input id="password" type="password" formControlName="password"
                                        [ngClass]="{'invalid': mensajes.password}">
                                    <label for="password">Contraseña</label>
                                    <span class="helper-text worng">
                                        {{ mensajes.password }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s12">
                                    <button [disabled]="loading"
                                        class="btn waves-effect waves-light col s12 gradient-45deg-indigo-blue"
                                        type="submit">
                                        Login
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s12">
                                    <p class="margin right-align medium-small">
                                        <a [routerLink]="['/', 'reset_password']">Olvidaste tu contraseña?</a>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ngx-spinner bdColor="rgba(0,0,0,0.3)" size="medium" color="#1a237e" type="ball-spin-clockwise" [fullScreen]="true">
</ngx-spinner>