<!DOCTYPE html>
<div class="card-panel mb-0 pb-0 pl-1 pr-1 pt-1">
    <div class="row">
        <div class="col s12 center-align">
            <a (click)="sincronizarDatos()"
                class="btn waves-effect waves-light mb-1 indigo btn-padding">
                Sincronizar
                <i class="material-icons left" style="margin-right: 8px !important;">
                    autorenew</i>
            </a>
        </div>
    </div>
</div>