import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html'
})
export class CarouselComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() idCarousel: any;
  @Input() imagenes: any[] | undefined;
  @Input() auto: boolean = true;
  @Input() indicators: boolean = false;
  selectIndex = 0;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.auto) {
      this.initCarousel();
    }
  }

  initCarousel() {
    const ele = $('#' + this.idCarousel);
    if (ele) {
      (ele as any).carousel({
        fullWidth: true,
        indicators: this.indicators
      });
      ele.addClass('carousel-open');
      this.selectIndex = 0;
    }
  }

  setImageCarousel(i: number) {
    const ele = $('#' + this.idCarousel);
    if (ele && ele.hasClass('carousel-open')) {
      (ele as any).carousel('set', i);
      this.selectIndex = i;
    }
  }

  ngOnDestroy(): void {
    const ele = $('#' + this.idCarousel);
    if (ele && ele.hasClass('carousel-open')) {
      ele.removeClass('carousel-open');
      (ele as any).carousel('destroy');
    }
  }

}
