import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/utils.service';
import { TableComponent } from './table.component';
import { TableService } from './table.service';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-table-image',
  templateUrl: './table-image.component.html'
})
export class TableImageComponent extends TableComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('fileInput', {static: false}) fileInput: ElementRef | undefined;
  accept = '*';
  loading = false;
  private allowedExtensions: string[] | undefined;

  constructor(renderer: Renderer2, utilsService: UtilsService, tableService: TableService,
              private ng2ImgMax: Ng2ImgMaxService, private spinner: NgxSpinnerService) {
    super(renderer, utilsService, tableService);
   }

  ngOnInit(): void {
    this.allowedExtensions = ['.jpe', '.jpg', '.jpeg', '.png'];
    this.accept = this.allowedExtensions.join();
    this.dtId = this.tableService.getIdTable();
    this.dtOptions = {
      language: {
        url: '../../../assets/i18n/spanish.json'
      },
      rowId: 'id',
      responsive: true,
      retrieve: true
    };
    Object.assign(this.dtOptions, this.opciones);
    const colImg = {
      title: 'Imagen',
      data: 'imagePreview',
      responsivePriority: 1,
      render: (data: any) => {
        const src = 'data:image/png;base64, ' + data;
        return '<div class="row no-margin">' +
        '<div class="col s1 no-padding">' +
        '<img src="'  + src + '" class="responsive-img"></div></div>';
      }, orderable: false
    };
    if (this.dtOptions.columns) {
      this.dtOptions.columns.unshift(colImg);
    }
    if (this.dtOptions.columns && !this.detalle) {
      this.dtOptions.columns.push(
        {
          title: ' ',
          data: 'id',
          className: 'min-width-30',
          responsivePriority: 1,
          render: (data: any) => {
            return '<div class="right-align">' +
            '<a class="pointer tabla-eliminar" delete-id="'  + data + '">' +
            '<i delete-id="'  + data + '" ' +
            'class="material-icons red-text">delete</i></a></div>';
          }, orderable: false
        }
      );
    }
    this.spinner.hide();
  }

  updateField(ev: any): void {
    this.loading = true;
    this.spinner.show();
    const image = ev.dataTransfer ? ev.dataTransfer.files[0] : ev.target.files[0];
    this.ng2ImgMax.resizeImage(image, 868, 868).subscribe(
      result => {
        const uploadedImage = new File([result], result.name);
        this.getImagePreview(ev, uploadedImage);
      },
      error => {
        this.loading = false;
        this.spinner.hide();
      }
    );
  }

  private getImagePreview(ev: any, uploadedImage: any, ): void {
    const reader = new FileReader();
    reader.onloadend = () => {
      const buff = Array.from(new Uint8Array(reader.result as ArrayBuffer));
      const result = btoa(buff.map((item) => String.fromCharCode(item)).join(''));
      this.eventCrear.emit({file: uploadedImage, image: result});
      ev.srcElement.setAttribute('value', null);
      this.loading = false;
      this.spinner.hide();
    };
    if (typeof uploadedImage === 'undefined') {
      this.cleanField(ev);
      this.loading = false;
      this.spinner.hide();
    } else {
      reader.readAsArrayBuffer(uploadedImage);
    }
  }

  private cleanField(event?: any): void {
    if (typeof this.fileInput !== 'undefined' && typeof this.fileInput.nativeElement !== 'undefined') {
      this.fileInput.nativeElement.value = '';
    }
    if (typeof event !== 'undefined' && typeof event.srcElement !== 'undefined') {
      event.srcElement.setAttribute('value', null);
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
