import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html'
})
export class SidenavComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() idSidenav: any;
  @Input() auto: boolean = true;
  @Input() edge: String = 'right';

  constructor() { }

  ngOnInit(): void {
  }

  initSidenav() {
    const ele = $('#' + this.idSidenav);
    if (ele) {
      const classStyle = this.edge === 'right' ? 'r-340' : 'l-260';
      (ele as any).sidenav({
        edge: this.edge,
        onOpenEnd: () => {
          const sidenavOverlay = $('.sidenav-overlay');
          sidenavOverlay.each((i, ele) => {
            if (ele.style.display === 'block') {
              ele.classList.add(classStyle);
            }
          });
        },
        onCloseStart: () => {
          const sidenavOverlay: any = $('.sidenav-overlay');
          if (sidenavOverlay) {
            $(sidenavOverlay).removeClass(classStyle);
          }
        }
      });
      ele.addClass('sidenav-init');
    }
  }

  open(): void {
    const ele = $('#' + this.idSidenav);
    if (ele && ele.hasClass('sidenav-init')) {
      (ele as any).sidenav('open');
    }
  }

  close(): void {
    const ele = $('#' + this.idSidenav);
    if (ele && ele.hasClass('sidenav-init')) {
      (ele as any).sidenav('close');
    }
  }

  ngAfterViewInit(): void {
    if (this.auto) {
      this.initSidenav();
    }
  }

  ngOnDestroy(): void {
    const ele = $('#' + this.idSidenav);
    if (ele && ele.hasClass('sidenav-init')) {
      ele.removeClass('sidenav-init');
      (ele as any).sidenav('destroy');
    }
  }

}
