import { Component, OnInit, ViewChildren, ElementRef, AfterViewInit } from '@angular/core';
import { FormControlName, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Validador } from '../validador';
import { Observable, fromEvent, merge } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { AuthService } from '../autorization/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Platform } from '@ionic/angular';
import { SwitchService } from '../switch/switch.service';
import { BreadcrumbsService } from '../breadcrumbs/breadcrumbs.service';
import { MenuService } from '../menu/menu.service';
import { UtilsService } from 'src/app/utils.service';
import { EnvService } from 'src/app/env.service';
import { DbService } from '../data/db.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  @ViewChildren(FormControlName, { read: ElementRef })
  formInputs: ElementRef[] = [];
  loginForm: FormGroup = new FormGroup({});
  mensajes: { [key: string]: string } = {};
  returnUrl: string | undefined;
  loading = false;
  isMobile = false;
  isOnline = true;
  autorizado: Observable<boolean> | undefined;
  private validador: Validador;

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router,
              private route: ActivatedRoute, private spinner: NgxSpinnerService, private envService: EnvService,
              private platform: Platform, private switchService: SwitchService, private menuService: MenuService,
              private breadcrumbsService: BreadcrumbsService, private utilsService: UtilsService) {
    this.validador = new Validador();
    if (this.authService.autorizadoValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.loading = false;
    this.spinner.hide();
    this.autorizado = this.authService.autorizado;
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    this.platform.ready().then(() => {
      this.isMobile = this.utilsService.isMobilePlatform();
      const idSwitch = this.switchService.obtenerIdOnline();
      if (this.isMobile && !this.switchService.isSwitchOn(idSwitch)) {
        this.returnUrl = '/';
      }
    });
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputs
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    merge(this.loginForm.valueChanges, ...controlBlurs).pipe(
      debounceTime(800)
    ).subscribe(value => {
      this.mensajes = this.validador.processMessages(this.loginForm);
    });
  }

  login(): void {
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.spinner.show();
    this.authLogin().then((res: any) => {
      if (!res.ok) {
        this.utilsService.alertaError({title: 'Error', message: res.error});
      }
    });
  }

  setIsOnline(ev: { value: boolean; }): void {
    this.isOnline = ev.value;
  }

  authLogin(): Promise<any> {
    return new Promise((resolve) => {
      this.authService.login(this.loginForm.controls.email.value,
        this.loginForm.controls.password.value)
        .subscribe(
          () => {
            this.authService.obtenerMenu(undefined, this.loginForm.controls.email.value).subscribe((res) => {
              if (res) {
                this.procesarMenu();
              }
              this.hideLoading();
              resolve({ok: true});
            }, () => {
              this.hideLoading();
              resolve({ok: false, error: 'No se pudieron procesar las opciones de Menú'});
            });
            const idSwitch = this.switchService.obtenerIdOnline();
            this.platform.ready().then(() => {
                if (!this.utilsService.isMobilePlatform() || this.switchService.isSwitchOn(idSwitch)) {
                  this.envService.obtenerConfiguraciones();
                } else {
                  this.authService.obtenerConfiguraciones();
                }
              });
          }, () => {
            this.hideLoading();
            resolve({ok: false, error: 'Usuario o contraseña incorrecta'});
          });
    });
  }

  private hideLoading(): void {
    this.loading = false;
    this.spinner.hide();
  }

  private procesarMenu(): void {
    const rUrl = this.returnUrl ? this.returnUrl.replace(/[0-9]/g, '') : '/';
    const permisos = this.authService.obtenerPermisos(rUrl);
    if (permisos) {
      const idSwitch = this.switchService.obtenerIdOnline();
      if (this.switchService.isSwitchOn(idSwitch)) {
        this.router.navigate([this.returnUrl]);
      } else {
        if (this.breadcrumbsService.isMenuOffline(this.returnUrl || '/')) {
          this.router.navigate([this.returnUrl]);
        } else {
          this.router.navigate(['/']);
        }
      }
    } else {
      const defaultPath = this.menuService.getDefaultPath();
      if (defaultPath) {
        this.router.navigate(['/', defaultPath]);
      } else {
        this.router.navigate(['/']);
      }
    }
  }
}
