<!DOCTYPE html>
<div class="input-field col s12">
    <i *ngIf="icono" class="material-icons prefix pt-2">{{icono}}</i>
    <ng-content></ng-content>
    <label [for]="idInput" [ngClass]="{'active': active}">
        {{label}}
        <span *ngIf="requerido" class="red-text">
            {{' *'}}
        </span></label>
    <span class="helper-text worng">
        {{ mensaje }}
    </span>
</div>
