
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentoPipe } from '../documento/documento.pipe';
import { CarouselComponent } from './carousel/carousel.component';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { ImageComponent } from './image/image.component';
import { SelectInputsComponent } from './select-inputs/select-inputs.component';
import { StepComponent } from './step/step.component';
import { StepperComponent } from './stepper/stepper.component';
import { TableImageComponent } from './table/table-image.component';
import { TableComponent } from './table/table.component';
import { TextInputsComponent } from './text-inputs/text-inputs.component';
import { TreeComponent } from './tree/tree.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TreeviewModule } from 'ngx-treeview';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ModalComponent } from './modal/modal.component';

const COMPONENENTES: any[] = [
    SelectInputsComponent,
    TableComponent,
    TextInputsComponent,
    StepperComponent,
    StepComponent,
    TableImageComponent,
    DocumentoPipe,
    NumbersOnlyDirective,
    TooltipDirective,
    CarouselComponent,
    TreeComponent,
    SidenavComponent,
    ModalComponent,
    ImageComponent
];

const MODULOS: any[] = [
    FormsModule,
    DataTablesModule,
    NgSelectModule,
    Ng2ImgMaxModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    TreeviewModule.forRoot()
];

const EXPORTAR = COMPONENENTES.concat(MODULOS);

const IMPORTAR = [CommonModule].concat(MODULOS);

@NgModule({
   declarations: COMPONENENTES,
   imports: IMPORTAR,
   exports: EXPORTAR
 })
 export class SharedModule { }
