import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  paths: any;
  pathsOffline = ['/productos-catalogo', '/pedidos-vendedor', '/clientes-vendedor'];

  constructor() {
    this.paths = {};
    this.paths['/sincronizar'] = 'Sincronizar datos';
    this.paths['/productos-catalogo'] = 'Catálogo productos';
    this.paths['/clientes-vendedor'] = 'Clientes';
    this.paths['/clientes-vendedor/crear'] = 'Crear cliente';
    this.paths['/clientes-vendedor/editar/'] = 'Editar cliente';
    this.paths['/clientes-vendedor/detalle/'] = 'Detalle cliente';
    this.paths['/productos'] = 'Productos';
    this.paths['/productos/crear'] = 'Crear producto';
    this.paths['/productos/editar/'] = 'Editar producto';
    this.paths['/clientes'] = 'Clientes';
    this.paths['/clientes/crear'] = 'Crear cliente';
    this.paths['/clientes/editar/'] = 'Editar cliente';
    this.paths['/clientes/detalle/'] = 'Detalle cliente';
    this.paths['/pedidos'] = 'Pedidos';
    this.paths['/pedidos/confirmar'] = 'Crear pedido';
    this.paths['/pedidos/crear'] = 'Catálogo productos';
    this.paths['/pedidos/editar/'] = 'Editar pedido';
    this.paths['/pedidos/detalle/'] = 'Detalle pedido';
    this.paths['/pedidos-vendedor'] = 'Pedidos';
    this.paths['/pedidos-vendedor/detalle/'] = 'Detalle pedido';
    this.paths['/pedidos-vendedor/confirmar'] = 'Crear pedido';
  }

  getTituloPagina(path: string): any {
    if (this.paths[path]) {
      return this.paths[path];
    }
    return false;
  }

  isMenuOffline(path: string): boolean {
    const ind = this.pathsOffline.indexOf(path);
    return ind > -1;
  }
}
