import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-text-inputs',
  templateUrl: './text-inputs.component.html',
  styleUrls: ['./text-inputs.component.css']
})
export class TextInputsComponent implements OnInit {

  @Input() idInput: any;
  @Input() icono: any;
  @Input() label: any;
  @Input() requerido: any;
  @Input() mensaje: any;
  @Input() active: any;

  constructor() { }

  ngOnInit(): void {
  }

}
