<!DOCTYPE html>
<div class="row">
    <div class="col s12">
        <div class="container">
            <div id="login-page" class="row">
                <div class="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card">
                    <form (ngSubmit)="reset()" [formGroup]="resetPasswordForm" novalidate autocomplete="false">
                        <div class="row">
                            <div class="input-field col s12">
                              <h5 class="ml-4">Se te olvidó tu contraseña</h5>
                              <p class="ml-4">Puedes restablecer tu contraseña</p>
                            </div>
                          </div>
                        <div class="row margin">
                            <div class="input-field col s12">
                                <i class="material-icons prefix pt-2">person_outline</i>
                                <input id="email" type="email" formControlName="email"
                                [ngClass]="{'invalid': mensajes.email}">
                                <label for="email" class="center-align">Email</label>
                                <span class="helper-text worng">
                                    {{ mensajes.email }}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s12">
                                <button
                                    class="btn waves-effect waves-light border-round col s12 gradient-45deg-purple-deep-orange"
                                    type="submit">
                                    Restablecer Contraseña
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s12">
                                <p class="margin medium-small">
                                    <a [routerLink]="['/', 'login']">Login</a>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>