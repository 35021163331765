<!doctype html>
<div class="file-field input-field">
  <div class="btn-floating btn-file">
    <i class="material-icons">attach_file</i>
    <input #fileInput type="file" (change)="updateField($event)" accept="{{accept}}">
  </div>
  <div class="file-path-wrapper hide">
    <input class="file-path validate" type="text">
  </div>
</div>
<div *ngIf="imagePreview" class="row">
  <div class="col m3 s12">
    <img [src]="sanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + imagePreview)"
    class="responsive-img">
  </div>
</div>