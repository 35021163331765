<!doctype html>
<div class="navbar navbar-fixed">
    <nav class="navbar-main navbar-color nav-collapsible sideNav-lock navbar-light">
        <a class="sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-effect waves-light show-on-medium-and-down"
            data-target="slide-out">
            <i class="material-icons">menu</i>
        </a>
        <div class="nav-wrapper valign-wrapper jc-center">
            <a class="brand-logo darken-1 pl-260" [routerLink]="['/']">
                <img class="responsive-img" style="height: 64px;padding: 10px 0;"
                    src="./../../../assets/img/Casa_Bisio_Logo.png" alt="casa bisio logo">
            </a>
        </div>
        <app-logout></app-logout>
    </nav>
</div>