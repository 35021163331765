import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './shared/login/login.component';
import { ResetPasswordComponent } from './shared/reset-password/reset-password.component';
import { AuthGuard } from './shared/autorization/auth.guard';
import { SincroComponent } from './sincro/sincro.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'reset_password', component: ResetPasswordComponent },
  { path: 'sincronizar', component: SincroComponent, canActivate: [AuthGuard] },
  { path: 'clientes', loadChildren: () => import('./cliente/cliente.module').then(m => m.ClienteModule)},
  { path: 'pedidos', loadChildren: () =>
  import('./pedido/pedido.module').then(m => m.PedidoModule)},
  { path: 'productos-catalogo', loadChildren: () =>
  import('./vendedor/productos/vendedor-productos.module').then(m => m.VendedorProductoModule)},
  { path: 'productos', loadChildren: () =>
  import('./producto/producto.module').then(m => m.ProductoModule)},
  { path: 'clientes-vendedor', loadChildren: () =>
  import('./vendedor/clientes/vendedor-clientes.module').then(m => m.VendedorClienteModule)},
  { path: 'pedidos-vendedor', loadChildren: () =>
  import('./vendedor/pedidos/vendedor-pedidos.module').then(m => m.VendedorPedidoModule)},
  { path: '',   redirectTo: '/productos', pathMatch: 'full' },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
