import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from '../env.service';

@Injectable({
  providedIn: 'root'
})
export class SincroService {

  private INSERT = 'INSERT';
  private UPDATE = 'UPDATE';
  private DELETE = 'DELETE';

  constructor(private http: HttpClient, private env: EnvService) { }

  sincronizar(): Observable<any> {
    return this.http.get(this.env.apiRest + '/sincronizar/entidades');
  }

  persistir(body: any): Observable<any> {
    return this.http.post(this.env.apiRest + '/sincronizar/persistir', body);
  }

  confirmar(): Observable<any> {
    return this.http.get(this.env.apiRest + '/sincronizar/confirmar');
  }

  insertEmpresa(record: any, query: any): void {
    const item = {
      id: record.id,
      apellido: record.apellido,
      direccion_fiscal: record.direccion_fiscal,
      documento: record.documento,
      email: record.email,
      fecha_desde: record.fecha_desde || new Date(),
      nombre: record.nombre,
      razon_social: record.razon_social,
      rut: record.rut,
      monedapordefecto_id: record.monedapordefecto_id
    };
    if (record.accion === this.INSERT){
      query.data.inserts.empresas.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.empresas.push(this.updateItem(item));
    }
  }

  insertEmpresasMonedas(record: any, query: any): void {
    const item = {
      id: record.id,
      empresa_id: record.id_empresa,
      moneda_id: record.id_moneda,
      conversion: record.conversion
    };
    query.data.inserts.empresas_monedas.push(item);

  }

  insertEmpresasPaises(record: any, query: any): void {
    const item = {
      id: record.id,
      empresa_id: record.empresa_id,
      pais_id: record.pais_id
    }; 
    query.data.inserts.empresas_paises.push(item);

  }

  insertEstados(record: any, query: any): void {
    const item = {
      id: record.id,
      nombre: record.nombre,
      empresa_id: record.empresa_id
    };

    if (record.accion === this.INSERT){
      query.data.inserts.estados.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.estados.push(this.updateItem(item));
    }

  }

  insertFormasDePago(record: any, query: any): void {
    const item = {
      id: record.id,
      nombre: record.nombre,
      empresa_id: record.empresa_id
    };

    if (record.accion === this.INSERT){
      query.data.inserts.formas_de_pago.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.formas_de_pago.push(this.updateItem(item));
    }
  }

  insertProductos(record: any, query: any): void {

    if (record.accion === this.DELETE){
      query.data.deletes.productos.push(this.deleteItem(record.id));
      return;
    }

    const item = {
      id: record.id,
      activo: record.activo,
      descripcion: record.descripcion,
      nombre: record.nombre || '',
      precio: record.precio,
      empresa_id: record.id_empresa,
      impuesto_id: record.id_impuesto,
      moneda_id: record.id_moneda
    };

    if (record.accion === this.INSERT){
      query.data.inserts.productos.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.productos.push(this.updateItem(item));
    }

  }

  insertImagenes(record: any, query: any, accion: any): void {
    if (accion === this.DELETE){
      query.data.deletes.imagenes.push(this.deleteItem(record.id));
      return;
    }
    const item = {
      id: record.id,
      contenido: record.contenido,
      nombre: record.nombre,
      portada: record.portada,
      tamaño: record.tamaño,
      tipo: record.tipo,
      producto_id: record.producto_id
    };
    query.data.inserts.imagenes.push(item);
  }

  insertCategorias(record: any, query: any): void {

    if (record.accion === this.DELETE){
      query.data.deletes.categoria.push(this.deleteItem(record.id));
      return;
    }

    const item = {
      id: record.id,
      activa: record.activa,
      descripcion: record.descripcion,
      es_raiz: record.es_raiz,
      id_padre: record.id_padre,
      nombre: record.nombre,
      posicion: record.posicion,
      empresa_id: record.id_empresa
    };

    if (record.accion === this.INSERT){
      query.data.inserts.categorias.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.categorias.push(this.updateItem(item));
    }

  }

  insertCategoriaProducto(record: any, query: any, accion: any): void {
    if (accion === this.DELETE){
      query.data.deletes.categoria_producto.push(this.deleteItem(record.id));
      return;
    }

    const item = {
      id: record.id,
      categoria_id: record.id_categoria,
      producto_id: record.id_producto,
      posicion: record.posicion
    };
    query.data.inserts.categoria_producto.push(item);
  }

  insertLogin(record: any, query: any): void {
    const item = {
      id: record.id,
      id_empresa: record.id_empresa,
      email: record.email,
      password: record.password,
      tipo_usuario: record.tipoUsuario,
      estado: record.estado
    };

    if (record.accion === this.INSERT){
      query.data.inserts.login.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.login.push(this.updateItem(item));
    }
  }

  insertMenuRoot(record: any, query: any): void {
    const item = {
      id: record.id,
      nombre: record.nombre
    };

    if (record.accion === this.INSERT){
      query.data.inserts.menu_root.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.menu_root.push(this.updateItem(item));
    }
  }

  insertMenu(record: any, query: any): void {
    const item = {
      id: record.id,
      nombre: record.nombre,
      path: record.path,
      padre_id: record?.padre?.id
    };

    if (record.accion === this.INSERT){
      query.data.inserts.menus.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.menus.push(this.updateItem(item));
    }
  }

  insertSucursales(record: any, query: any): void {
    const item = {
      id: record.id,
      nombre: record.nombre,
      empresa_id: record?.empresa?.id,
      pais_id: record.pais_id
    };

    if (record.accion === this.INSERT){
      query.data.inserts.sucursales.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.sucursales.push(this.updateItem(item));
    }
  }

  insertDepositos(record: any, query: any, accion: any): void {
    const item = {
      id: record.id,
      nombre: record.nombre,
      sucursal_id: record.sucursal_id
    };
    const valido = record.sucursal_id;
    if (accion === this.INSERT && valido){
      query.data.inserts.depositos.push(item);
    }

    if (accion === this.UPDATE && valido){
      query.data.updates.depositos.push(this.updateItem(item));
    }
  }

  insertSucursalesImpuestos(record: any, query: any, accion: any): void {
    const item = {
      id: record.id,
      sucursal_id: record.sucursal_id,
      impuesto_id: record.impuesto_id
    };
    const valido = record.sucursal_id && record.impuesto_id;
    if (accion === this.INSERT && valido){
      query.data.inserts.sucursales_impuestos.push(item);
    }

    if (accion === this.UPDATE && valido){
      query.data.updates.sucursales_impuestos.push(this.updateItem(item));
    }
  }

  insertSucursalesMonedas(record: any, query: any, accion: any): void {
    const item = {
      id: record.id,
      sucursal_id: record.sucursal_id,
      moneda_id: record.moneda_id
    };
    const valido = record.sucursal_id && record.moneda_id;
    if (accion === this.INSERT && valido){
      query.data.inserts.sucursales_monedas.push(item);
    }

    if (accion === this.UPDATE && valido){
      query.data.updates.sucursales_monedas.push(this.updateItem(item));
    }
  }

  insertProductosSucursal(record: any, query: any): void {
    const item = {
      id: record.producto_sucursal_id,
      precio: record.precio,
      impuesto_id: record.id_impuesto,
      moneda_id: record.id_moneda,
      producto_id: record.producto_id,
      sucursal_id: record.id_sucursal
    };

    if (record.accion === this.INSERT){
      query.data.inserts.productos_sucursal.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.productos_sucursal.push(this.updateItem(item));
    }
  }

  insertProductosSucursalDeposito(record: any, query: any): void {
    const item = {
      id: record.id,
      producto_sucursal_id: record.producto_sucursal_id,
      deposito_id: record.deposito_id
    };

    if (record.accion === this.INSERT){
      query.data.inserts.productos_sucursal.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.productos_sucursal.push(this.updateItem(item));
    }
  }

  insertColaboradores(record: any, query: any): void {
    const item = {
      id: record.id,
      nombre: record.nombre,
      apellido: record.apellido,
      email: record.email,
      estado: record.estado,
      password: record.password
    };

    if (record.accion === this.INSERT){
      query.data.inserts.colaboradores.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.colaboradores.push(this.updateItem(item));
    }
  }

  insertSucursalColaborador(record: any, query: any): void {
    const item = {
      id: record.id,
      sucursal_id: record.sucursal_id,
      colaborador_id: record.colaborador_id,
      fecha_desde: record.fecha_desde,
      fecha_hasta: record.fecha_hasta
    };

    if (record.accion === this.INSERT){
      query.data.inserts.sucursal_colaborador.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.sucursal_colaborador.push(this.updateItem(item));
    }
  }

  insertPermiso(record: any, query: any): void {

    if (record.accion === this.DELETE){
      query.data.deletes.permisos.push(this.deleteItem(record.id));
      return;
    }

    const item = {
      id: record.id,
      crear: record.crear,
      modificar: record.modificar,
      eliminar: record.eliminar,
      ver: record.ver,
      colaborador_id: record.id_colaborador,
      menu_id: record.menu_id
    };

    if (record.accion === this.INSERT){
      query.data.inserts.permisos.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.permisos.push(this.updateItem(item));
    }

  }

  insertClientes(record: any, query: any): void {

    if (record.accion === this.DELETE){
      query.data.deletes.clientes.push(this.deleteItem(record.id));
      return;
    }

    const item = {
      id: record.id,
      email: record.email,
      nombre: record.nombre,
      apellido: record.apellido,
      direccion_fiscal: record.direccion_fiscal,
      documento: record.documento,
      fecha_desde: record.fecha_desde,
      razon_social: record.razon_social,
      rut: record.rut,
      sucursal_id: record.id_sucursal,
      vendedor_id: record.id_vendedor,
      alta_offline: 0,
      update_offline: 0
    };

    if (record.accion === this.INSERT){
      query.data.inserts.clientes.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.clientes.push(this.updateItem(item));
    }

  }

  insertDirecciones(record: any, query: any, accion: string): void {
    if (accion === this.DELETE) {
      query.data.deletes.direcciones.push(this.deleteItem(record.id));
      return;
    }

    const item = {
      id: record.id,
      activo: record.activo,
      alias: record.alias,
      borrado: record.borrado,
      celular: record.celular,
      codigo_postal: record.codigo_postal,
      direccion1: record.direccion1,
      direccion2: record.direccion2,
      fecha_agregado: record.fecha_agregado,
      fecha_modificado: record.fecha_modificado,
      id_ciudad: record.id_ciudad,
      id_pais: record.id_pais,
      id_provincia: record.id_provincia,
      otro: record.otro,
      rut: record.rut,
      razon_social: record.razon_social,
      telefono: record.telefono,
      cliente_id: record.id_cliente,
      alta_offline: 0,
      update_offline: 0
    };
    query.data.inserts.direcciones.push(item);

  }

  insertConfiguraciones(record: any, query: any): void {
    if (record.accion === this.DELETE){
      query.data.deletes.configuraciones.push(this.deleteItem(record.id));
      return;
    }

    const item = {
      id: record.id,
      nombre: record.nombre,
      descripcion: record.descripcion,
      valor: record.valor
    };

    if (record.accion === this.INSERT){
      query.data.inserts.configuraciones.push(item);
    }

    if (record.accion === this.UPDATE){
      query.data.updates.configuraciones.push(this.updateItem(item));
    }

  }

  private updateItem(item: any): any {
    return {
      set: item,
      where: {
        id: item.id
      }
    };
  }

  private deleteItem(id: any): any {
    return {
        id
    };
  }
}
