import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SwitchService {

  id = 0;
  on: any = {};
  idOnline: any = undefined;
  private subject: any;

  constructor() { }

  obtenerId(): string {
    return 'switch-' + this.id++;
  }

  obtenerIdOnline(): string {
    if (!this.idOnline) {
      this.idOnline = 'switchOnline';
    }
    return this.idOnline;
  }

  getSubjectSwitch(id: any): Observable<any> {
    if (!this.on[id]) {
      this.on[id] = false;
    }
    if (!this.subject) {
      this.subject = new BehaviorSubject<any>({id, value: this.on[id]});
    }
    return this.subject.asObservable();
  }

  setSubjectSwitch(id: any, value: boolean): void {
    this.on[id] = value;
    if (!this.subject) {
      this.subject = new BehaviorSubject<any>({id, value: this.on[id]});
    } else {
      return this.subject.next({id, value: this.on[id]});
    }
  }

  isSwitchOn(id: any): boolean {
    return typeof this.on[id] === 'undefined' || this.on[id];
  }

}
