import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  // The values that are defined here are the default values that can
  // be overridden by env.js

  public loadConfiguraciones: BehaviorSubject<any> = new BehaviorSubject(false);

  // API url
  public apiRest = '';

  // Whether or not to enable debug mode
  public enableDebug = true;

  // Whether or not to enable data test SQLite
  public enabledDataTest = true;

  // Whether or not to enable data init load SQLite
  public enabledDataInit = true;

  public timeout = 9999999999;

  public configuraciones: Configuracion[] = [];

  constructor(private http: HttpClient) { }

  setEnv(): any {

    const browserWindow: any = window || {};
    const browserWindowEnv = browserWindow.__env || {};

  // Assign environment variables from browser window to env
  // In the current implementation, properties from env.js overwrite defaults from the EnvService.
  // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
    // tslint:disable-next-line:forin
    for (const key in browserWindowEnv) {
    if (browserWindowEnv.hasOwnProperty(key)) {
      // tslint:disable-next-line:no-string-literal
      const windowVal: any = window;
      const that: any = this;
      that[key] = windowVal.__env[key];
    }

    return Promise.resolve();
  }

  }

  obtenerConfiguraciones(): void {
    this.loadConfiguraciones = new BehaviorSubject(false);
    this.configuraciones = [];
    const url = this.apiRest + '/configuracion/listar';
    this.http
    .get(url)
    .toPromise()
    .then((response: any) => {
        this.configuraciones = response?.records ? response.records : [];
    })
    .catch(() => Promise.resolve())
    .finally(() =>  {
      this.loadConfiguraciones.next(true);
    });
  }

  getConfig(key: any): any {
    return this.configuraciones.find(c => c.nombre === key);
  }

  isConfigReady(): Observable<boolean> {
    return this.loadConfiguraciones.asObservable();
  }

}

export class Configuracion {
  id: any;
  nombre: any;
  valor: any;
  descripcion: any;
}
