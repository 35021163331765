import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { UtilsService } from '../utils.service';
import { takeUntil } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { SincroService } from './sincro.service';
import { EnvService } from '../env.service';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { SwitchService } from '../shared/switch/switch.service';
import { DbTransaction } from '@ionic-native/sqlite/ngx';

@Component({
  selector: 'app-sincro',
  templateUrl: './sincro.component.html'
})
export class SincroComponent implements OnInit, OnDestroy {

  isMobile: Observable<boolean> | undefined;
  private unsubscribeDbReady = new Subject<any>();
  private unsubscribeDataDirecciones = new Subject<any>();
  private unsubscribeDataImagenes = new Subject<any>();
  private unsubscribeDataCategorias = new Subject<any>();
  private unsubscribeDataMonedas = new Subject<any>();
  private unsubscribeDataInit = new Subject<any>();
  private unsubscribeSincronizar = new Subject<any>();
  private unsubscribePersistir = new Subject<any>();
  private unsubscribeConfirmar = new Subject<any>();
  private mensajeSincronizar = 'Datos sincronizados correctamente.';
  private dataInit: BehaviorSubject<any> = new BehaviorSubject(false);
  private dataMonedas: BehaviorSubject<any> = new BehaviorSubject(false);
  private dataDirecciones: BehaviorSubject<any> = new BehaviorSubject(false);
  private dataImagenes: BehaviorSubject<any> = new BehaviorSubject(false);
  private dataCategorias: BehaviorSubject<any> = new BehaviorSubject(false);
  private query: any = { data: { inserts: {}, updates: {}, deletes: {} } };
  private UPDATE = 'UPDATE';
  private DELETE = 'DELETE';
  private INSERT = 'INSERT';

  constructor(private sincroService: SincroService, private envService: EnvService,
    private utilsService: UtilsService, private platform: Platform, private sqlPorter: SQLitePorter,
    private switchService: SwitchService) {
  }

  ngOnInit(): void {
    this.isMobile = this.utilsService.getSubjectIsMobile();
  }

  sincronizarDatos(): void {
    if (this.utilsService.isMobilePlatform()) {
      if (!this.validarOnline()) {
        return;
      }
      this.destroyUnsubcribeDbReady();
      this.unsubscribeDbReady = new Subject<any>();
      this.utilsService.isDBReady().pipe(takeUntil(this.unsubscribeDbReady)).subscribe(ready => {
        if (ready) {
          this.loadDataMonedas();
          this.alertaInfo();
          this.dataMonedas.pipe(takeUntil(this.unsubscribeDataMonedas)).subscribe(resMon => {
            if (this.envService.enabledDataInit && resMon) {
              Swal.update({
                title: 'Sincronizando datos loadDataInit'
              });
              this.dataInit = new BehaviorSubject(false);
              this.destroyUnsubscribeDataInit();
              this.unsubscribeDataInit = new Subject<any>();
              this.utilsService.loadDataInit(this.dataInit);
              this.dataInit.pipe(takeUntil(this.unsubscribeDataInit)).subscribe(res => {
                if (res) {
                  this.obtenerDataPersistir();
                }
              });
            } else {
              this.obtenerDataPersistir();
            }
          });
        }
      });
    }
  }

  private loadDataMonedas(): void {
    const storageDB = this.utilsService.getStorageDB();
    storageDB.executeSql('SELECT * FROM monedas', []).then((res: any) => {
      this.dataMonedas.next(!(res.rows.length > 0));
    }).catch(() => {
      this.dataMonedas.next(false);
    });
  }

  private deleteDirecciones(id: any, last: boolean): void {
    const storageDB = this.utilsService.getStorageDB();
    storageDB.executeSql('SELECT * FROM direcciones ' +
    'WHERE cliente_id = ?', [id]).then((res: any) => {
      if (res.rows.length > 0) {
        for (let i = 0; res.rows.length > i; i++) {
          this.sincroService.insertDirecciones(
            { id: res.rows.item(i).id }, this.query, this.DELETE
          );
        }
      }
    }).finally(() => {
      if (last) {
        this.dataDirecciones.next(true);
      }
    });
  }

  private deleteImagenes(id: any, last: boolean): void {
    const storageDB = this.utilsService.getStorageDB();
    storageDB.executeSql('SELECT * FROM imagenes ' +
    'WHERE producto_id = ?', [id]).then((res: any) => {
      if (res.rows.length > 0) {
        for (let i = 0; res.rows.length > i; i++) {
          this.sincroService.insertImagenes(
            { id: res.rows.item(i).id }, this.query, this.DELETE
          );
        }
      }
    }).finally(() => {
      if (last) {
        this.dataImagenes.next(true);
      }
    });
  }

  private deleteCategorias(id: any, last: boolean): void {
    const storageDB = this.utilsService.getStorageDB();
    storageDB.executeSql('SELECT * FROM categoria_producto ' +
    'WHERE producto_id = ?', [id]).then((res: any) => {
      if (res.rows.length > 0) {
        for (let i = 0; res.rows.length > i; i++) {
          this.sincroService.insertCategoriaProducto(
            { id: res.rows.item(i).id }, this.query, this.DELETE
          );
        }
      }
    }).finally(() => {
      if (last) {
        this.dataCategorias.next(true);
      }
    });
  }

  private validarOnline(): boolean {
    const idOnline: any = this.switchService.obtenerIdOnline();
    return (this.switchService.isSwitchOn(idOnline));
  }

  private persistir(clientes: any[], clientesBD: any[], pedidos: any[]): void {
    this.destroyUnsubscribePersistir();
    this.unsubscribePersistir = new Subject<any>();
    Swal.update({
      title: 'Enviando datos locales al servidor'
    });
    this.sincroService.persistir({ pedidos, clientes })
      .pipe(takeUntil(this.unsubscribePersistir)).subscribe(response => {
        if (!this.utilsService.handleBackendErrors(response)) {
          const query: any = { data: {} };
          this.cleanClientes(query, clientesBD, response?.errores ?? response.clientesErrores);
          this.cleanPedidos(query, pedidos, response?.errores ?? response.pedidosErrores);
          const storageDB = this.utilsService.getStorageDB();
          this.sqlPorter.importJsonToDb(storageDB, query).then(() => {
            this.sincronizar();
          }).finally(() => {
            Swal.close();
          });
        }
      }, error => {
        Swal.close();
        this.utilsService.handleHttpError(error);
      });
  }

  private cleanClientes(query: any, clientes: any[], errores?: any[]): void {
    if (clientes.length > 0) {
      if (!query.data.deletes) {
        query.data.deletes = { clientes: [] };
      } else {
        query.data.deletes.clientes = [];
      }
      if (errores && errores.length > 0) {
        clientes.forEach((item: any) => {
          const ind = errores.findIndex((err: any) => err.id === item.id);
          if (ind < 0) {
            query.data.deletes.clientes.push({
              id: item.id
            });
          }
        });
      } else {
        clientes.forEach((item: any) => {
          query.data.deletes.clientes.push({
            id: item.id
          });
        });
      }
    }
  }

  private cleanPedidos(query: any, pedidos: any[], errores?: any[]): void {
    if (pedidos.length > 0) {
      if (!query.data.deletes) {
        query.data.deletes = { pedidos: [] };
      } else {
        query.data.deletes.pedidos = [];
      }
      if (errores && errores.length > 0) {
        pedidos.forEach((item: any) => {
          const ind = errores.findIndex((err: any) => err.id === item.id);
          if (ind < 0) {
            query.data.deletes.pedidos.push({
              id: item.id
            });
          }
        });
      } else {
        pedidos.forEach((item: any) => {
          query.data.deletes.pedidos.push({
            id: item.id
          });
        });
      }
    }
  }

  private sincronizar(): void {
    this.destroyUnsubscribeSincronizar();
    this.unsubscribeSincronizar = new Subject<any>();
    Swal.update({
      title: 'Obteniendo datos del servidor'
    });
    this.sincroService.sincronizar()
      .pipe(takeUntil(this.unsubscribeSincronizar)).subscribe(response => {
        if (!this.utilsService.handleBackendErrors(response)) {
          this.procesarData(response);
        }
      }, error => {
        Swal.close();
        this.utilsService.handleHttpError(error);
      });
  }

  private confirmar(): void {
    this.destroyUnsubscribeConfirmar();
    this.unsubscribeConfirmar = new Subject<any>();
    Swal.update({
      title: 'Realizando confirmación de datos'
    });
    this.sincroService.confirmar()
      .pipe(takeUntil(this.unsubscribeConfirmar)).subscribe(response => {
        Swal.close();
        this.utilsService.handleBackendErrors(response, this.mensajeSincronizar);
      }, error => {
        Swal.close();
        this.utilsService.handleHttpError(error);
      });
  }

  private alertaInfo(): void {
    Swal.fire({
      title: 'Sincronizando',
      icon: 'info',
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    }).then(() => {
    });
  }

  private obtenerDataPersistir(): void {
    const storageDB = this.utilsService.getStorageDB();
    const clientes: any = [];
    const pedidos: any = [];
    Swal.update({
      title: 'Obtenidendo datos par enviar al servidor'
    });
    storageDB.transaction((tx: DbTransaction) => {
      tx.executeSql('SELECT * FROM clientes WHERE alta_offline = ? OR update_offline = ?', [1, 1], (_tx: any, res: any) => {
        if (res.rows.length > 0) {
          for (let i = 0; res.rows.length > i; i++) {
            tx.executeSql('SELECT * FROM direcciones WHERE cliente_id = ?', [res.rows.item(i).id], (_tx: any, resDir: any) => {
              const direcciones: any = [];
              if (resDir.rows.length > 0) {
                for (let j = 0; resDir.rows.length > j; j++) {
                  direcciones.push(
                    {
                      id: resDir.rows.item(j).id,
                      alias: resDir.rows.item(j).alias,
                      nombre: resDir.rows.item(j).nombre,
                      apellido: resDir.rows.item(j).apellido,
                      rut: resDir.rows.item(j).rut,
                      razon_social: resDir.rows.item(j).razon_social,
                      telefono: resDir.rows.item(j).telefono,
                      codigo_postal: resDir.rows.item(j).codigo_postal,
                      celular: resDir.rows.item(j).celular,
                      direccion1: resDir.rows.item(j).direccion1,
                      direccion2: resDir.rows.item(j).direccion2,
                      cliente_id: res.rows.item(j).id,
                      otro: resDir.rows.item(j).otro,
                      id_pais: resDir.rows.item(j).id_pais,
                      id_provincia: resDir.rows.item(j).id_provincia,
                      id_ciudad: resDir.rows.item(j).id_ciudad,
                      id_empresa: resDir.rows.item(j).id_empresa
                    }
                  );
                }
              }
              clientes.push({
                id: res.rows.item(i).id,
                email: res.rows.item(i).email,
                nombre: res.rows.item(i).nombre,
                apellido: res.rows.item(i).apellido,
                direccion_fiscal: res.rows.item(i).direccion_fiscal,
                documento: res.rows.item(i).documento,
                razon_social: res.rows.item(i).razon_social,
                rut: res.rows.item(i).rut,
                direcciones,
              });
            });
          }
        }
      });
      tx.executeSql('SELECT * FROM pedidos', [], (_tx: any, res: any) => {
        if (res.rows.length > 0) {
          for (let i = 0; res.rows.length > i; i++) {
            tx.executeSql('SELECT * FROM pedidos_detalle WHERE id_pedido = ?', [res.rows.item(i).id], (_tx: any, resDet: any) => {
              const detalle: any = [];
              if (resDet.rows.length > 0) {
                for (let i = 0; resDet.rows.length > i; i++) {
                  detalle.push(
                    {
                      cantidad: resDet.rows.item(i).cantidad,
                      id_deposito: resDet.rows.item(i).id_deposito,
                      id_producto: resDet.rows.item(i).id_producto
                    });
                }
              }
              pedidos.push({
                id: res.rows.item(i).id,
                id_cliente: res.rows.item(i).id_cliente,
                id_cliente_offline: res.rows.item(i).id_cliente_offline,
                id_direccion: res.rows.item(i).id_direccion,
                id_direccion_envio: res.rows.item(i).id_direccion_envio,
                id_forma_pago: res.rows.item(i).id_forma_pago,
                id_moneda: res.rows.item(i).id_moneda,
                id_sucursal: res.rows.item(i).sucursal_id,
                id_vendedor: res.rows.item(i).vendedor_id,
                observaciones: res.rows.item(i).observaciones,
                detalle
              });
            });
          }
        }
      });
    }).finally(() => {
      const clientesBD = {... clientes};
      if (clientes.length > 0 || pedidos.length > 0) {
        clientes.forEach((item: any) => {
          if (item.alta_offline) {
            item.id_cliente_offline = item.id;
          } else if (item.update_offline) {
            item.id_cliente = item.id;
          }
          delete item.id;
        });
        pedidos.forEach((item: any) => {
          const ind = clientes.findIndex((cli: any) =>
          cli.id_cliente_offline && Number(cli.id_cliente_offline) === Number(item.id_cliente));
          if (ind > -1) {
            item.id_cliente_offline = item.id_cliente;
            item.id_cliente = null;
          } else {
            item.id_cliente_offline = null;
          }
        });
        this.persistir(clientes, clientesBD, pedidos);
      } else {
        this.sincronizar();
      }
    });
  }

  private procesarData(res: any): void {
    this.platform.ready().then(() => {
      this.utilsService.isDBReady().subscribe(resReady => {
        const storageDB = this.utilsService.getStorageDB();
        if (resReady) {
          this.query = { data: { inserts: {}, updates: {}, deletes: {} } };
          this.insertEmpresa(res);
          this.insertLogin(res);
          this.insertMenu(res);
          this.insertEstados(res);
          this.insertFormasDePago(res);
          this.insertCategorias(res);
          this.insertProductos(res);
          this.insertSucursales(res);
          this.insertColaboradores(res);
          this.insertPermisos(res);
          this.insertClientes(res);
          this.insertConfiguraciones(res);

          if (Object.keys(this.query.data.inserts).length === 0
            && Object.keys(this.query.data.updates).length === 0
            && Object.keys(this.query.data.deletes).length === 0) {
            Swal.close();
          } else {
            if (Object.keys(this.query.data.inserts).length === 0) {
              delete this.query.data.inserts;
            }
            if (Object.keys(this.query.data.updates).length === 0) {
              delete this.query.data.updates;
            }
            if (Object.keys(this.query.data.deletes).length === 0) {
              delete this.query.data.deletes;
            }
            this.dataDirecciones.pipe(takeUntil(this.unsubscribeDataDirecciones)).subscribe((readyDir: any) => {
              if (readyDir) {
                this.dataImagenes.pipe(takeUntil(this.unsubscribeDataImagenes)).subscribe((readyImg: any) => {
                  if (readyImg) {
                    this.dataCategorias.pipe(takeUntil(this.unsubscribeDataCategorias)).subscribe((readyCat: any) => {
                      if (readyCat) {
                        this.sqlPorter.importJsonToDb(storageDB, this.query).then(() => {
                          this.confirmar();
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        }
      });
    });
  }

  private insertEmpresa(res: any): void {
    if (res.empresa) {
      this.initKeyData('empresas');
      this.sincroService.insertEmpresa(res.empresa, this.query);
      this.removeKeyEmpty('empresas');

      if (res.empresa.monedas) {
        this.initKeyData('empresas_monedas');
        res.empresa.monedas.forEach((item2: any) => {
          this.sincroService.insertEmpresasMonedas(item2, this.query);
        });
        this.removeKeyEmpty('empresas_monedas');
      }

      if (res.empresa.paises) {
        this.initKeyData('empresas_paises');
        res.empresa.paises.forEach((item3: any) => {
          this.sincroService.insertEmpresasPaises(item3, this.query);
        });
        this.removeKeyEmpty('empresas_paises');
      }
    }
  }

  private insertEstados(res: any): void {
    if (res.estados) {
      this.initKeyData('estados');
      res.estados.forEach((item: any) => {
        this.sincroService.insertEstados(item, this.query);
      });
      this.removeKeyEmpty('estados');
    }
  }

  private insertFormasDePago(res: any): void {
    if (res.formasDePago) {
      this.initKeyData('formas_de_pago');
      res.formasDePago.forEach((item: any) => {
        this.sincroService.insertFormasDePago(item, this.query);
      });
      this.removeKeyEmpty('formas_de_pago');
    }
  }

  private insertCategorias(res: any): void {
    if (res.categorias) {
      this.initKeyData('categorias');
      res.categorias.forEach((item: any) => {
        this.sincroService.insertCategorias(item, this.query);
      });
      this.removeKeyEmpty('categorias');
    }
  }

  private insertProductos(res: any): void {
    if (res.productos) {
      this.initKeyData('productos');
      this.initKeyData('imagenes');
      this.initKeyData('categoria_producto');
      const updatesCount = res.productos.filter((p: any) => p.accion === this.UPDATE).length;
      let updates = 0;
      if (updatesCount === 0) {
        this.dataImagenes.next(true);
        this.dataCategorias.next(true);
      } else {
        this.dataImagenes.next(false);
        this.dataCategorias.next(false);
      }
      res.productos.forEach((item: any) => {
        this.sincroService.insertProductos(item, this.query);

        if (item.accion === this.UPDATE) {
            item.accion = this.INSERT;
            updates ++;
            this.deleteImagenes(item.id, updates >= updatesCount);
            this.deleteCategorias(item.id, updates >= updatesCount);
        }

        if (item.imagenes) {
          item.imagenes.forEach((item2: any) => {
            this.sincroService.insertImagenes(item2, this.query, item.accion);
          });
        }

        if (item.categoriasProducto) {
          item.categoriasProducto.forEach((item2: any) => {
            this.sincroService.insertCategoriaProducto(item2, this.query, item.accion);
          });
        }

      });
      this.removeKeyEmpty('productos');
      this.removeKeyEmpty('imagenes');
      this.removeKeyEmpty('categoria_producto');
    }
  }

  private insertLogin(res: any): void {
    if (res.login) {
      this.initKeyData('login');
      res.login.forEach((item: any) => {
        this.sincroService.insertLogin(item, this.query);
      });
      this.removeKeyEmpty('login');
    }
  }

  private insertMenu(res: any): void {
    if (res.menus) {
      this.initKeyData('menus');
      res.menus.forEach((item: any) => {
        this.sincroService.insertMenu(item, this.query);
      });
      this.removeKeyEmpty('menus');
    }
    if (res.menusRoot) {
      this.initKeyData('menu_root');
      res.menusRoot.forEach((item: any) => {
        this.sincroService.insertMenuRoot(item, this.query);
      });
      this.removeKeyEmpty('menu_root');
    }
  }

  private insertSucursales(res: any): void {
    if (res.sucursal) {
      this.initKeyData('sucursales');
      this.sincroService.insertSucursales(res.sucursal, this.query);
      this.removeKeyEmpty('sucursales');

      if (res.sucursal.monedas) {
        this.initKeyData('sucursales_monedas');
        res.sucursal.monedas.forEach((item2: any) => {
          this.sincroService.insertSucursalesMonedas(item2, this.query, res.sucursal.accion);
        });
        this.removeKeyEmpty('sucursales_monedas');
      }

      if (res.sucursal.impuestos) {
        this.initKeyData('sucursales_impuestos');
        res.sucursal.impuestos.forEach((item3: any) => {
          this.sincroService.insertSucursalesImpuestos(item3, this.query, res.sucursal.accion);
        });
        this.removeKeyEmpty('sucursales_impuestos');
      }

      if (res.sucursal.depositos) {
        this.initKeyData('depositos');
        res.sucursal.depositos.forEach((item4: any) => {
          this.sincroService.insertDepositos(item4, this.query, res.sucursal.accion);
        });
        this.removeKeyEmpty('depositos');
      }
    }
  }

  private insertColaboradores(res: any): void {
    if (res.vendedor) {
      this.initKeyData('colaboradores');
      this.sincroService.insertColaboradores(res.vendedor, this.query);
      this.removeKeyEmpty('colaboradores');

      if (res.sucursalColaborador) {
        this.initKeyData('sucursal_colaborador');
        res.sucursalColaborador.forEach((item2: any) => {
          this.sincroService.insertSucursalColaborador(item2, this.query);
        });
        this.removeKeyEmpty('sucursal_colaborador');
      }
    }
  }

  private insertPermisos(res: any): void {
    if (res.permisos) {
      this.initKeyData('permisos');
      res.permisos.forEach((item: any) => {
        this.sincroService.insertPermiso(item, this.query);
      });
      this.removeKeyEmpty('permisos');
    }
  }

  private insertClientes(res: any): void {
    if (res.clientes) {
      this.initKeyData('clientes');
      this.initKeyData('direcciones');
      const updatesCount = res.clientes.filter((c: any) => c.accion === this.UPDATE).length;
      let updates = 0;
      if (updatesCount === 0) {
        this.dataDirecciones.next(true);
      } else {
        this.dataDirecciones.next(false);
      }
      res.clientes.forEach((item: any) => {
        this.sincroService.insertClientes(item, this.query);
        if (item.direcciones) {
          if (item.accion === this.UPDATE) {
            item.accion = this.INSERT;
            updates ++;
            this.deleteDirecciones(item.id, updates >= updatesCount);
          }
          item.direcciones.forEach((item2: any) => {
            this.sincroService.insertDirecciones(item2, this.query, item.accion);
          });
        }
      });
      this.removeKeyEmpty('clientes');
      this.removeKeyEmpty('direcciones');
    }
  }

  private insertConfiguraciones(res: any): void {
    if (res.configuraciones) {
      this.initKeyData('configuraciones');
      res.configuraciones.forEach((item: any) => {
        this.sincroService.insertConfiguraciones(item, this.query);
      });
      this.removeKeyEmpty('configuraciones');
    }
  }

  private initKeyData(key: string): void {
    this.query.data.inserts[key] = [];
    this.query.data.updates[key] = [];
    this.query.data.deletes[key] = [];
  }

  private removeKeyEmpty(key: string): void {
    if (this.query.data.inserts
      && this.query.data.inserts[key]
      && this.query.data.inserts[key].length === 0) {
      delete this.query.data.inserts[key];
    }
    if (this.query.data.updates
      && this.query.data.updates[key]
      && this.query.data.updates[key].length === 0) {
      delete this.query.data.updates[key];
    }
    if (this.query.data.deletes
      && this.query.data.deletes[key]
      && this.query.data.deletes[key].length === 0) {
      delete this.query.data.deletes[key];
    }
  }

  private destroyUnsubscribe(): void {
    this.destroyUnsubcribeDbReady();
    this.destroyUnsubscribeDataInit();
    this.destroyUnsubscribeDataMonedas();
    this.destroyUnsubscribeDataDirecciones();
    this.destroyUnsubscribeDataCategorias();
    this.destroyUnsubscribeDataImagenes();
    this.destroyUnsubscribeSincronizar();
    this.destroyUnsubscribePersistir();
    this.destroyUnsubscribeConfirmar();
  }

  private destroyUnsubcribeDbReady(): void {
    this.unsubscribeDbReady.next();
    this.unsubscribeDbReady.complete();
  }

  private destroyUnsubscribeDataDirecciones(): void {
    this.unsubscribeDataDirecciones.next();
    this.unsubscribeDataDirecciones.complete();
  }

  private destroyUnsubscribeDataImagenes(): void {
    this.unsubscribeDataImagenes.next();
    this.unsubscribeDataImagenes.complete();
  }

  private destroyUnsubscribeDataCategorias(): void {
    this.unsubscribeDataCategorias.next();
    this.unsubscribeDataCategorias.complete();
  }

  private destroyUnsubscribeDataInit(): void {
    this.unsubscribeDataInit.next();
    this.unsubscribeDataInit.complete();
  }

  private destroyUnsubscribeDataMonedas(): void {
    this.unsubscribeDataMonedas.next();
    this.unsubscribeDataMonedas.complete();
  }

  private destroyUnsubscribeSincronizar(): void {
    this.unsubscribeSincronizar.next();
    this.unsubscribeSincronizar.complete();
  }

  private destroyUnsubscribePersistir(): void {
    this.unsubscribePersistir.next();
    this.unsubscribePersistir.complete();
  }

  private destroyUnsubscribeConfirmar(): void {
    this.unsubscribeConfirmar.next();
    this.unsubscribeConfirmar.complete();
  }

  private destroyDataInit(): void {
    this.dataInit.next(false);
    this.dataInit.complete();
  }

  private destroyDataDirecciones(): void {
    this.dataDirecciones.next(false);
    this.dataDirecciones.complete();
  }

  ngOnDestroy(): void {
    this.destroyDataInit();
    this.destroyDataDirecciones();
    this.destroyUnsubscribe();
  }

}
