import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StepperService } from '../stepper/stepper.service';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html'
})
export class StepComponent implements OnInit {

  @Input() nombre: string | undefined;
  @Input() activo: boolean | undefined;
  @Input() wrong: boolean | undefined;
  @Input() prev: FormGroup | undefined;
  @Input() ready: boolean | undefined;
  private id: any;
  private unsubscribe = new Subject<any>();

  constructor(private stepperService: StepperService) { }

  ngOnInit(): void {
    this.unsubscribe = new Subject<any>();
    this.id = this.stepperService.obtenerId();
    this.stepperService.getSubjectStepClick()
      .pipe(takeUntil(this.unsubscribe)).subscribe(idActivo => {
        if (idActivo) {
          this.activo = idActivo === this.id;
        }

      });
  }

  activarStep(force?: boolean): void {
    if (force) {
      this.stepperService.setSubjectStepClick(this.id);
      return;
    }
    if ((!this.prev || (this.prev && this.prev.valid)) && this.ready) {
      this.stepperService.setSubjectStepClick(this.id);
    }
  }

}
