import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from './shared/autorization/auth.service';
import { BreadcrumbsService } from './shared/breadcrumbs/breadcrumbs.service';
import { MenuService } from './shared/menu/menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  titulo = '';
  autorizado: Observable<boolean> | undefined;
  private unsubscribe = new Subject<any>();

  constructor(private router: Router, private breadcrumbsService: BreadcrumbsService,
              private authService: AuthService, private config: NgSelectConfig, private menuService: MenuService) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((ev) => {
        this.setTituloPagina(ev);
      });
    this.config.notFoundText = 'No se encontraron resultados';
    this.config.loadingText = 'Buscando…';
    this.config.clearAllText = 'Borrar';
    this.config.openOnEnter = true;

  }

  ngOnInit(): void {
    this.autorizado = this.authService.autorizado;
  }

  setTituloPagina(ev: any): void {
    let path = ev.url;
    path = path.replace(/[0-9]/g, '');
    if (path === '/') {
      const defaultPath = this.menuService.getDefaultPath();
      if (defaultPath) {
        path = defaultPath;
      }
    }
    if (!(path as string).includes('login')) {
      path = decodeURIComponent(path);
    }
    this.titulo = this.breadcrumbsService.getTituloPagina(path);
  }

  private destroyUnsubscribe(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnDestroy(): void {
    this.destroyUnsubscribe();
  }

}
