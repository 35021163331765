<!DOCTYPE html>
<ng-container *ngIf="!(autorizado | async); else default">
  <body class="vertical-layout vertical-menu-collapsible login-bg page-header-dark vertical-modern-menu 2-columns">
    <router-outlet></router-outlet>
  </body>
</ng-container>

<ng-template #default>
  <body class="vertical-layout page-header-light vertical-menu-collapsible vertical-dark-menu 2-columns">
    <header class="page-topbar" id="header">
      <app-header></app-header>
    </header>
    <app-menu></app-menu>
    <div id="main">
      <div class="row">
        <app-breadcrumbs [titulo]="titulo"></app-breadcrumbs>
        <div class="col s12">
          <div class="container">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </body>
</ng-template>