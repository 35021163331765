import { Component, OnInit, ElementRef, ViewChildren, AfterViewInit } from '@angular/core';
import { FormControlName, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Validador } from '../validador';
import { Observable, fromEvent, merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {

  @ViewChildren(FormControlName, { read: ElementRef })
  formInputs: ElementRef[] = [];
  resetPasswordForm: FormGroup = new FormGroup({});
  mensajes: { [key: string]: string } = {};
  validationMessages: { [key: string]: { [key: string]: string } };
  private validador: Validador;

  constructor(private fb: FormBuilder) {
    this.validationMessages = {
      email: {
        required: 'El email es requerido',
        email: 'El email no es correcto'
      }
    };
    this.validador = new Validador();
   }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputs
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    merge(this.resetPasswordForm.valueChanges, ...controlBlurs).pipe(
      debounceTime(800)
    ).subscribe(value => {
      this.mensajes = this.validador.processMessages(this.resetPasswordForm);
    });
  }

  reset(): void {
    if (this.resetPasswordForm.invalid) {
      return;
    }
  }

}
