import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html'
})
export class TreeComponent implements OnInit {

  @Input() items: TreeviewItem[] = [];
  @Output() eventSelected = new EventEmitter<any>();
  values: number[] | undefined;
  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: true
  });

  constructor() { }

  ngOnInit(): void {
  }

  onSelectedChange(ev: any): void {
    this.procesarTree(ev);
    this.values = [...new Set(this.values)];
    this.eventSelected.emit(this.values);
  }

  onFilterChange(value: string): void {
  }

  private procesarTree(ev: any): void {
    this.values = [];
    for (const item of this.items) {
      this.values = this.values?.concat(this.procesarPadres(item));
    }
    this.values = this.values?.concat(ev);
  }

  private procesarPadres(item: TreeviewItem): any[] {
    let valores = [];
    const esPadre = (item as any).internalChildren && (item as any).internalChildren.length > 0;
    if ((item as any).internalChecked) {
      valores.push(item.value);
    }
    if (esPadre) {
      for (const hijo of (item as any).internalChildren) {
        valores = valores.concat(this.procesarPadres(hijo));
      }
    }
    return valores;
  }
}
