import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeEsUY from '@angular/common/locales/es-UY';
registerLocaleData(localeEsUY);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './shared/login/login.component';
import { ResetPasswordComponent } from './shared/reset-password/reset-password.component';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component';
import { EnvService } from './env.service';
import { HeaderComponent } from './shared/header/header.component';
import { MenuComponent } from './shared/menu/menu.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { RouteReuseStrategy } from '@angular/router';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { Drivers } from '@ionic/storage';
import { SwitchComponent } from './shared/switch/switch.component';
import { LogoutComponent } from './shared/logout/logout.component';
import { SincroComponent } from './sincro/sincro.component';
import { dataServiceProvider } from './shared/data/data.service.provider';
import { SharedModule } from './shared/shared.module';

export function envServiceFactory(envService: EnvService): any {
  return () => envService.setEnv();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    BreadcrumbsComponent,
    HeaderComponent,
    MenuComponent,
    SwitchComponent,
    LogoutComponent,
    SincroComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(
      {
        name: 'mayoristas.db',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
      }
    )
  ],
  providers: [
    SQLite,
    SQLitePorter,
    dataServiceProvider,
    { provide: LOCALE_ID, useValue: 'es-UY' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {
      provide: APP_INITIALIZER,
      useFactory: envServiceFactory,
      deps: [EnvService, Platform],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
