<!DOCTYPE html>
<div class="input-field col s12 mt-0" [ngClass]="classInput">
<label [for]="idInput" [ngClass]="{'active': active}" class="label-select">
    {{label}}
    <span *ngIf="requerido" class="red-text">
        {{' *'}}
    </span></label>
    <ng-content></ng-content>
    <span class="helper-text worng">
        {{ mensaje }}
    </span>
</div>

