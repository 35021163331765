<!DOCTYPE html>
<div class="carousel carousel-slider" [id]="idCarousel">
    <ng-container *ngFor="let img of imagenes">
        <ng-container *ngIf="img.contenido; else noFoto">
            <a class="carousel-item">
                <img [src]="sanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + img.contenido)" class="responsive-img">
            </a>
        </ng-container>
    </ng-container>
</div>
<div class="row center">
    <div class="col s12 img">
        <div class="indicators-images">
            <ng-container *ngIf="imagenes && imagenes.length > 0">
                <ng-container *ngFor="let img of imagenes; let i = index">
                    <ng-container *ngIf="img.contenido; else noFoto">
                        <a class="item" (click)="setImageCarousel(i)"
                        [ngClass]="{'selected': i === selectIndex}">
                            <img [src]="sanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + img.contenido)"
                                class="responsive-img">
                        </a>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #noFoto>
    <img src="./../../assets/img/no_photo.png" class="responsive-img">
</ng-template>
