<!DOCTYPE html>
<div [id]="idModal" class="modal" [ngClass]="{'modal-fixed-header': fixedHeader}">
    <div class="modal-header" *ngIf="header">        
      <h5>{{titulo}}<a class="modal-close right"><i class="material-icons">close</i></a></h5>
      </div>
    <div class="modal-content">
      <a *ngIf="!header" class="modal-close btn-cerrar indigo-text">
        <i class="material-icons">close</i>
      </a>
        <ng-content></ng-content>
    </div>
    <div class="modal-footer" *ngIf="footer">
      <ng-container *ngIf="modo === 'crear'; else modoEditar">
        <a (click)="crear()" class="btn indigo waves-effect waves-indigo mr-2">Crear</a>
        <a (click)="crear(true)" class=" btn indigo waves-effect waves-indigo">Crear y Continuar</a>
      </ng-container>
    </div>
  </div>

  <ng-template #modoEditar>
    <a *ngIf="modo === 'editar'"
    (click)="editar()" class="btn indigo waves-effect waves-indigo">Editar</a>
  </ng-template>
