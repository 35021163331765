import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-inputs',
  templateUrl: './select-inputs.component.html',
  styleUrls: ['./select-inputs.component.css']
})
export class SelectInputsComponent implements OnInit, AfterViewInit {

  @Input() idInput: any;
  @Input() icono: any;
  @Input() label: any;
  @Input() requerido: any;
  @Input() mensaje: any;
  @Input() active: any;
  @Input() classInput: any = '';

  constructor() { }

  ngAfterViewInit(): void {
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (isChrome) {
      $('.ng-select-container input').attr('autocomplete', 'chrome-off');
    }
  }

  ngOnInit(): void {
  }

}
