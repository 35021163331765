import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/env.service';
import { map } from 'rxjs/operators';
import { Menu, MenuRoot } from '../menu/menu';
import { MenuService } from '../menu/menu.service';
import { StorageService } from 'src/app/storage.service';
import { SwitchService } from '../switch/switch.service';
import * as CryptoJS from 'crypto-js';
import { UtilsService } from 'src/app/utils.service';
import { BreadcrumbsService } from '../breadcrumbs/breadcrumbs.service';
import { SQLiteObject } from '@ionic-native/sqlite';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import { DbService } from '../data/db.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private autorizadoSubject: BehaviorSubject<any>;
  public autorizado: Observable<any>;
  private permisos: any = {};
  private subject!: Subject<any>;
  private menus!: Subject<any>;

  constructor(private http: HttpClient, private env: EnvService, private breadcrumbsService: BreadcrumbsService,
    private menuService: MenuService, private router: Router, private utilsService: UtilsService, private platform: Platform,
    private storageService: StorageService, private switchService: SwitchService, private storage: Storage) {
    this.autorizadoSubject = new BehaviorSubject<any>(this.storageService.get('token'));
    this.autorizado = this.autorizadoSubject.asObservable();
  }

  public get autorizadoValue(): any {
    return this.autorizadoSubject.value;
  }

  public setAutorizado(res: any): void {
    this.autorizadoSubject.next(res);
  }

  public logout(login?: boolean): void {
    this.autorizadoSubject.next(null);
    this.storage.create().then((storage: Storage) => {
      storage.clear().then(() => {
        this.platform.ready().then(() => {
          if (this.utilsService.isMobilePlatform()) {
            this.router.navigate(['/'], { replaceUrl: true });
            window.location.reload();
          } else {
            if (login) {
              this.router.navigate(['/', 'login'], { replaceUrl: true });
            }
          }
        });
      });
    });
  }

  public login(email: string, password: string): Observable<any> {
    const idSwitch = this.switchService.obtenerIdOnline();
    if (this.switchService.isSwitchOn(idSwitch)) {
      return this.loginAPI(email, password);
    } else {
      return this.loginDB(email, password);
    }
  }

  private loginAPI(email: string, password: string): Observable<any> {
    return this.http.post<any>(this.env.apiRest + '/seguridad/login', { email, password })
      .pipe(map(res => {
        if (res?.token) {
          this.storageService.set('token', res.token);
          this.autorizadoSubject.next(res);
          return res;
        }
      }));
  }

  private loginDB(email: string, password: string): Observable<any> {
    let passwordDB = '';
    this.utilsService.isDBReady().subscribe(ready => {
      const storageDB = this.utilsService.getStorageDB();
      if (ready) {
        return from(storageDB.executeSql(
          'SELECT password, id_empresa, tipo_usuario FROM login WHERE email = ? order by email limit 1', [email])
          .then((res: any) => {
            if (res.rows.length === 1) {
              passwordDB = res.rows.item(0).password;
            }
            if (this.validarPasword(passwordDB, password)) {
              this.loginStorage(email, res.rows.item(0), storageDB);
            } else {
              throw new Error('Password incorrecta');
            }
          }));
      } else {
        return new BehaviorSubject(false).asObservable();
      }
    });
    return new BehaviorSubject(false).asObservable();
  }

  public loginStorage(email: string, dtLogin: any, storageDB: SQLiteObject): void {
    let idVendedorDB: any;
    let idSucursalDB: any;
    if (dtLogin.tipo_usuario === 'VENDEDOR') {
      storageDB.executeSql('SELECT c.id, sc.sucursal_id FROM colaboradores c ' +
        'LEFT JOIN sucursal_colaborador sc ON sc.colaborador_id = c.id ' +
        'WHERE c.email = ? order by c.email limit 1', [email]).then((res: any) => {
          if (res.rows.length === 1) {
            idVendedorDB = res.rows.item(0).id;
            idSucursalDB = res.rows.item(0).sucursal_id || 1;
          }
          this.generarToken(email, dtLogin, idVendedorDB, idSucursalDB);
        });
    } else {
      this.generarToken(email, dtLogin);
    }

  }

  private generarToken(email: string, dtLogin: any, idVendedorDB?: any, idSucursalDB?: any): void {
    const token = {
      email,
      id_empresa: dtLogin.id_empresa,
      vendedor_id: idVendedorDB,
      sucursal_id: idSucursalDB
    };
    this.storageService.set('token', token);
    this.autorizadoSubject.next(token);
    setTimeout(() => {
      this.logout(true);
    }, 1000 * 60 * 60);
  }

  public inicializarPermisos(path: any): void {
    this.permisos[path] = {};
  }

  public registrarPermisos(menu: Menu): void {
    if (menu.path) {
      this.permisos[menu.path] = menu.permisos;
    }
  }

  public obtenerPermisos(path: any): any {
    if (this.permisos[path]) {
      return this.permisos[path];
    }
    return undefined;
  }

  public limpiarPermisos(): void {
    this.permisos = {};
  }

  public getPermisos(): any {
    return this.permisos;
  }

  public obtenerMenu(url?: UrlSegment[], email?: string, state?: RouterStateSnapshot): Observable<boolean> {
    const idSwitch = this.switchService.obtenerIdOnline();
    const online = this.switchService.isSwitchOn(idSwitch);

    this.subject = new Subject<boolean>();

    if (online) {
      return this.obtenerMenuAPI(url, state);
    } else {
      return this.obtenerMenuDB(url, email, state);
    }
  }

  private obtenerMenuAPI(url?: UrlSegment[], state?: RouterStateSnapshot): Observable<boolean> {
    this.menuService.obtenerMenu().subscribe(res => {
      this.procesarMenu(res, url);
      if (state) {
        this.router.navigateByUrl(state.url);
      }
    }, () => {
      this.subject.next(false);
    });
    return this.subject.asObservable();
  }

  private obtenerMenuDB(url?: UrlSegment[], email?: string, state?: RouterStateSnapshot): Observable<boolean> {
    this.obtenerObservableMenuDB(email).subscribe(res => {
      this.procesarMenu(res, url, true);
      if (state) {
        this.router.navigateByUrl(state.url);
      }
    }, () => {
      this.subject.next(false);
    });
    return this.subject.asObservable();
  }

  private obtenerObservableMenuDB(email?: string): Observable<boolean> {
    if (this.menus) {
      return this.menus.asObservable();
    }
    const menus: any[] = [];
    this.menus = new Subject<boolean>();
    this.utilsService.isDBReady().subscribe(resReady => {
      if (resReady) {
        const storageDB = this.utilsService.getStorageDB();
        storageDB.executeSql('SELECT tipo_usuario FROM login WHERE email = ? order by email limit 1', [email])
          .then((res: any) => {
            if (res.rows.length === 1) {
              if (res.rows.item(0).tipo_usuario === 'ADMIN') {
                storageDB.executeSql('SELECT menu_root.nombre as padre_nombre, menus.id, menus.nombre, menus.path, menus.padre_id ' +
                  'FROM menus LEFT JOIN menu_root ON menus.padre_id = menu_root.id ' +
                  'order by menus.padre_id', [])
                  .then((resMenus: any) => {
                    const mapMenu: any = {};
                    for (let i = 0; i < resMenus.rows.length; i++) {
                      const item = resMenus.rows.item(i);
                      const menu = {
                        id: item.id,
                        nombre: item.nombre,
                        path: item.path,
                        permisos: {
                          crear: true,
                          modificar: true,
                          eliminar: true,
                          ver: true
                        }
                      };
                      if (mapMenu[item.padre_id]) {
                        mapMenu[item.padre_id].menus.push(menu);
                      } else {
                        const menuRoot = {
                          id: item.padre_id,
                          nombre: item.padre_nombre,
                          menus: [menu]
                        };
                        mapMenu[item.padre_id] = menuRoot;
                      }
                    }
                    Object.keys(mapMenu).forEach((key) => {
                      menus.push(mapMenu[key]);
                    });
                    this.menus.next(menus);
                  }, () => {
                    this.menus.next(false);
                  });
              } else {
                if (res.rows.item(0).tipo_usuario === 'VENDEDOR') {
                  storageDB.executeSql('SELECT mr.nombre as padre_nombre, m.id, m.nombre, m.path, m.padre_id' +
                    ',p.crear, p.eliminar, p.ver, p.modificar ' +
                    'FROM colaboradores c LEFT JOIN permisos p ON c.id = p.colaborador_id ' +
                    'LEFT JOIN menus m ON m.id = p.menu_id ' +
                    'LEFT JOIN menu_root mr ON m.padre_id = mr.id ' +
                    'WHERE c.email = ? ' +
                    'order by m.padre_id', [email])
                    .then((resMenus: any) => {
                      const mapMenu: any = {};
                      for (let i = 0; i < resMenus.rows.length; i++) {
                        const item = resMenus.rows.item(i);
                        const menu = {
                          id: item.id,
                          nombre: item.nombre,
                          path: item.path,
                          permisos: {
                            crear: item.crear,
                            modificar: item.modificar,
                            eliminar: item.eliminar,
                            ver: item.ver
                          }
                        };
                        if (mapMenu[item.padre_id]) {
                          mapMenu[item.padre_id].menus.push(menu);
                        } else {
                          const menuRoot = {
                            id: item.padre_id,
                            nombre: item.padre_nombre,
                            menus: [menu]
                          };
                          mapMenu[item.padre_id] = menuRoot;
                        }
                      }
                      Object.keys(mapMenu).forEach((key) => {
                        menus.push(mapMenu[key]);
                      });
                      this.menus.next(menus);
                    }, () => {
                      this.menus.next(false);
                    });
                }
              }
            } else {
              this.menus.next(false);
              throw new Error('No existe login con el email ' + email);
            }
          }, () => {
            this.menus.next(false);
          });
      }
    });
    return this.menus.asObservable();
  }

  private procesarMenu(res: any, url?: UrlSegment[], offline?: boolean): void {
    const menu = res ? res : [];
    const defaultRoute = { path: '', redirectTo: '/', pathMatch: 'full' };
    let setDefault = false;
    this.menuService.setSubjectMenu(menu);
    this.limpiarPermisos();
    const menuRoot = res as MenuRoot[];
    this.inicializarPermisos('/sincronizar');
    if (menuRoot) {
      menuRoot.forEach((root: MenuRoot) => {
        root.menus?.forEach((child: Menu) => {
          this.registrarPermisos(child);
          if (!setDefault && child.path) {
            if (offline) {
              if (this.breadcrumbsService.isMenuOffline(child.path)) {
                setDefault = true;
                defaultRoute.redirectTo = child.path;
              }
            } else {
              setDefault = true;
              defaultRoute.redirectTo = child.path;
            }
          }
        });
      });
      this.menuService.setDefaultPath(defaultRoute.redirectTo.substring(1));
      const check = url ? this.tienePermisos(url) : true;
      this.subject.next(check);
    } else {
      this.subject.next(false);
    }
  }

  public tienePermisos(url: UrlSegment[]): boolean {
    const path = url[0].path;
    const permisos = this.obtenerPermisos('/' + path);
    if (permisos) {
      if (url[1]) {
        switch (url[1].path) {
          case 'detalle':
            return permisos.ver;
          case 'crear':
          case 'confirmar':
            return permisos.crear;
          case 'editar':
            return permisos.modificar;
        }
      } else {
        return true;
      }
    } else {
      const defaultPath = this.menuService.getDefaultPath();
      defaultPath ? this.router.navigate(['/', defaultPath]) :
        this.router.navigate(['/']);
    }
    return false;
  }

  public canActive(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, lazy?: boolean): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>((resolve) => {
      this.storage.create().then((db: Storage) => {
        db.get('token').then((val) => {
          const autorizado = this.autorizadoValue || val;
          if (autorizado) {
            this.setAutorizado(autorizado);
            let url: UrlSegment[] = [];
            if (lazy && route?.pathFromRoot[1]?.url) {
              Object.assign(url, route?.pathFromRoot[1]?.url);
            }
            url = url.concat(route.url);
            if (Object.keys(this.getPermisos()).length > 0) {
              const tienePermisos = this.tienePermisos(url);
              resolve(tienePermisos ? true : false);
            } else {
              const idSwitch = this.switchService.obtenerIdOnline();
              this.platform.ready().then(() => {
                if (!this.utilsService.isMobilePlatform() || this.switchService.isSwitchOn(idSwitch)) {
                  this.env.obtenerConfiguraciones();
                } else {
                  this.obtenerConfiguraciones();
                }
              });
              resolve(this.obtenerMenu(url, undefined, state).toPromise());
            }
          } else {
            resolve(this.router.createUrlTree(['/', 'login'], { queryParams: { returnUrl: state.url } }));
          }
        }).catch(() => {
          resolve(this.router.createUrlTree(['/', 'login'], { queryParams: { returnUrl: state.url } }));
        });
      }).catch(() => {
        resolve(this.router.createUrlTree(['/', 'login'], { queryParams: { returnUrl: state.url } }));
      });
    });
  }

  obtenerConfiguraciones(): void {
    this.env.loadConfiguraciones = new BehaviorSubject(false);
    this.env.configuraciones = [];
    const items: any = [];

    this.utilsService.isDBReady().subscribe(resReady => {
      if (resReady) {
        const storageDB = this.utilsService.getStorageDB();
        storageDB.executeSql('SELECT * FROM configuraciones',
          []).then(res => {
            if (res.rows.length > 0) {
              for (let i = 0; res.rows.length > i; i++) {
                items.push({
                  id: res.rows.item(i).id,
                  nombre: res.rows.item(i).nombre,
                  descripcion: res.rows.item(i).descripcion,
                  valor: res.rows.item(i).valor
                });
              }
            }
          }).finally(() => {
            this.env.configuraciones = items ?? [];
            this.env.loadConfiguraciones.next(true);
          });
      }
    });
  }

  private validarPasword(passwordDB: string, passwordToCheck: string): boolean {
    return passwordDB === 'SHA1:' + CryptoJS.SHA1(passwordToCheck).toString(CryptoJS.enc.Hex);
  }

}
