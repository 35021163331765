import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit, AfterViewInit {

  @Input() idModal: any;
  @Input() titulo: any;
  @Input() modo: any;
  @Input() fixedHeader: boolean = true;
  @Input() header: boolean = true;
  @Input() footer: boolean = true;
  @Output() eventCrear = new EventEmitter<any>();
  @Output() eventEditar = new EventEmitter<any>();
  @Output() eventCloseEnd = new EventEmitter<any>();
  @Output() eventOpenEnd = new EventEmitter<any>();
  private initModal = false;

  constructor() {
    this.modo = this.modo || 'crear';
  }

  ngAfterViewInit(): void {
    const ele = $('#' + this.idModal);
    if (ele) {
    this.initModal = true;
     (ele as any).modal({
      onOpenEnd: () => {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        });
        this.eventOpenEnd.emit();
      },
      onCloseEnd: () => {
        this.eventCloseEnd.emit();
      }
     });
    }
  }

  ngOnInit(): void {
  }

  open(): void {
    const ele = $('#' + this.idModal);
    if (ele) {
     (ele as any).modal('open');
    }
  }

  close(): void {
    const ele = $('#' + this.idModal);
    if (ele) {
     (ele as any).modal('close');
    }
  }

  crear(continuar?: boolean): void {
    this.eventCrear.emit({continuar});
  }

  editar(): void {
    this.eventEditar.emit();
  }

}
