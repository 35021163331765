<!DOCTYPE html>
<aside class="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-dark sidenav-active-rounded">
    <div class="brand-sidebar">
        <a class="brand-logo darken-1 hide-on-med-and-down" [routerLink]="['/']">
            <img class="responsive-img"
            src="./../../../assets/img/Casa_Bisio_Logo_transparente.png" alt="casa bisio logo">
        </a>
        <ul id="slide-out"
            class="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow collapsible-accordion">
            <ng-container *ngIf="menu | async as Menu">
                <ng-container *ngFor="let root of Menu">
                    <li class="bold">
                        <a class="collapsible-header waves-effect waves-cyan"><i
                                class="material-icons">content_paste</i><span
                                class="menu-title">{{root.nombre}}</span></a>
                        <div class="collapsible-body">
                            <ul class="collapsible collapsible-sub">
                                <ng-container *ngFor="let item of root.menus">
                                    <li><a (click)="navRouteLink(item.path)" class="pointer"
                                            [ngClass]="{'active': item.path === pathActive }">
                                            <i
                                                class="material-icons">radio_button_unchecked</i><span>{{item.nombre}}</span></a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </li>
                </ng-container>
                <li class="bold" *ngIf="isMobile && isOnline">
                    <a class="collapsible-header waves-effect waves-cyan"><i
                            class="material-icons">content_paste</i><span
                            class="menu-title">Sincronizar</span></a>
                    <div class="collapsible-body">
                        <ul class="collapsible collapsible-sub">
                            <li><a (click)="navRouteLink(pathSincro)" [ngClass]="{'active': pathSincro === pathActive }">
                                    <i class="material-icons">radio_button_unchecked</i><span>Sincronizar</span></a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="bold" *ngIf="!isMobile">
                    <a class="collapsible-header waves-effect waves-cyan"><i
                            class="material-icons">content_paste</i><span
                            class="menu-title">Descargas</span></a>
                    <div class="collapsible-body">
                        <ul class="collapsible collapsible-sub">
                            <li><a (click)="descargarAPK()" class="pointer">
                                    <i class="material-icons">radio_button_unchecked</i><span>Apk</span></a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>
</aside>