<!DOCTYPE html>
<table datatable [dtOptions]="dtOptions" class="table dtr-inline" [id]="dtId"></table>
<div class="row">
    <div class="col s12 right-align" [ngClass]="dtId">
        <div class="file-field input-field">
            <button class="btn-floating btn-file btn-crear-item" [disabled]="loading">
                <i class="material-icons">attach_file</i>
                <input #fileInput type="file" (change)="updateField($event)" accept="{{accept}}">
            </button>
            <div class="file-path-wrapper hide">
                <input class="file-path validate" type="text">
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0,0,0,0.3)" size="medium" color="#1a237e" type="ball-spin-clockwise" [fullScreen]="true">
</ngx-spinner>