<!DOCTYPE html>
<div class="switch" [ngClass]="classSwitch">
    <label>
      <ng-container *ngIf="greatMedium | async">
        {{offTexto}}
      </ng-container>
      <input type="checkbox" [(ngModel)]="on" [id]="idSwitch"
      (change)="setSwitch($event, idSwitch)">
      <span class="lever">
        <label *ngIf="header && !(greatMedium | async)"
         [ngClass]="{'switch-small-text': header}"
         [ngStyle]="{'top': !header ? '0' : (small | async) ? '35px' : '45px'}">
          {{on ? onTexto : offTexto}}
        </label>
      </span>
      <ng-container *ngIf="greatMedium | async">
        {{onTexto}}
      </ng-container>
    </label>
  </div>
