import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvService } from 'src/app/env.service';
import { MenuRoot } from './menu';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private menu!: MenuRoot[];
  private subject: any;
  private defaultPath = '';

  constructor(private http: HttpClient, private env: EnvService) { }

  obtenerMenu(): Observable<any> {
    const url = this.env.apiRest + '/menu/listar';
    return this.http.get(url);
  }

  getMenu(): MenuRoot[] {
    return this.menu;
  }

  getSubjectMenu(): Observable<any> {
    if (!this.subject) {
      this.subject = new BehaviorSubject<any>(undefined);
    }
    return this.subject.asObservable();
  }

  setSubjectMenu(menu: any): void {
    if (!this.subject) {
      this.subject = new BehaviorSubject<any>(menu);
    } else {
      return this.subject.next(menu);
    }
  }

  setDefaultPath(path: any): void {
    this.defaultPath = path;
  }

  getDefaultPath(): any {
    return this.defaultPath !== '' ? this.defaultPath : undefined;
  }

  obtenerAPK(): any {
    return this.http.get(this.env.apiRest + '/descargas/apk', { responseType: "blob" });
  }

}
